import React from 'react';
import csImage from '../../../../assets/01cs.png'; // 01CS에 사용할 이미지 경로

const EmojiGroup = ({ onEmojiClick }) => {
  // 각 이모지 항목과 오버레이 텍스트를 정의합니다.
  const emojis = [
    { emoji: '∞', label: '00MATH', hoverText: '서비스 개발중' },
    { 
      emoji: (
        <img 
          src={csImage} 
          alt="01CS" 
          style={{ width: '40px', height: '40px', marginBottom: '8px' }} 
        />
      ), 
      label: '01CS', 
      hoverText: 'version 0.2.1' 
    }, // 이미지 크기 조정 가능
    { emoji: '🎈', label: '02Phys', hoverText: '서비스 개발중' },
    { emoji: '🔬', label: '03Chem', hoverText: '서비스 개발중' },
    { emoji: '🤖', label: '04Robo', hoverText: '서비스 개발중' },
    { emoji: '💿', label: '08Semi', hoverText: '서비스 개발중' },
    { emoji: '⚡', label: '11Circ', hoverText: '서비스 개발중' },
    { emoji: '⚪', label: '19Go', hoverText: 'with 두고팡' },
  ];

  return (
    <div className="flex justify-center">
      <div className="grid gap-8 bg-white grid-cols-4 grid-rows-2 md:grid-cols-8 md:grid-rows-1 max-w-[1024px] mx-[3%] mt-[15%] md:mt-[5%]">
        {emojis.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center p-4 group rounded-lg transition-all cursor-pointer"
            style={{ boxSizing: 'border-box' }}
            onClick={() => onEmojiClick(item.label)}
          >
            {/* 마우스 오버 시 나타나는 작은 텍스트 영역 */}
            <div className="absolute -top-10 md:-top-14 transform text-xs md:text-base -mx-4 px-1 py-3 font-bold text-white bg-[#D00070] rounded-lg opacity-0 group-hover:opacity-100">
              {item.hoverText}
            </div>
            {/* 이모지 또는 이미지 */}
            <span className="text-5xl">{item.emoji}</span>
            {/* 텍스트 */}
            <span className="text-gray-600 mt-3">{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmojiGroup;