// tileId : 타일의 고유 id(문제 데이터의 파일명과 일치)
// stauts : 타일 분류(src/components/landing/container/contents/tileComponents/Tile.js 컴포넌트의 statusStyles 참조)
// img : 이미지
// title1 : 부제목
// title2 : 메인 제목
// description : 설명
// title1Color, title2Color, descriptionColor : 텍스트 색상
// width, height : 데스크탑 기준 타일 크기
// mobileWidth, mobileHeight : 모바일 기준 타일 크기
// modalContent : 해당 타일을 클릭했을때 오픈되는 모달창의 요소
//  - modalTitle : 제목
//  - modalDetails : 세부내용
//  - modalExtraInfo : 링크 텍스트
//  - modalLink : 링크
//  - modalType : 커리큘럼으로 연결되는 고유 id(src/data/curriculumData.js 컴포넌트의 id 참조)
//  - openInNewTab : 새창열기 여부(True/False)

import bestBack01 from '../assets/tile/bestBack01.webp';
import bestBack02 from '../assets/tile/bestBack02.gif';
import bestBack03 from '../assets/tile/bestBack03.png';
import bestBack05 from '../assets/tile/bestBack05.webp';
import bestBack06 from '../assets/tile/bestBack06.gif';
import bestBack07 from '../assets/tile/bestBack07.webp';
import bestBack08 from '../assets/tile/bestBack08.webp';
import bestBack09 from '../assets/tile/bestBack09.webp';
import starterImage01 from '../assets/tile/starter01.webp';
import starterImage02 from '../assets/tile/starter02.webp';
import starterImage03 from '../assets/tile/starter03.webp';
import blockImage01 from '../assets/tile/block01.gif';
import blockImage02 from '../assets/tile/block02.gif';
import module_py_begin from '../assets/tile/module_py_begin.webp';
import module_data_begin from '../assets/tile/module_data_begin.webp';
import module_computer_begin from '../assets/tile/module_computer_begin.webp';
import module_sw_begin from '../assets/tile/module_sw_begin.webp';
import forStarter from '../assets/tile/for_starter.png';

export const allTilesData = {
  bestTiles: [
    {
      tileId: 'python-cursor',
      status: ['dev', 'course'],
      img: bestBack05,
      title1: '비전공자, 청소년도 할 수 있는 👾',
      title2: '기초 파이썬 + CURSOR',
      description: '생성형 AI의 강력함을 체감해보세요.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '최근 가장 핫한 개발환경🔥, CURSOR',
        modalDetails: `The AI Code Editor. CURSOR
                      그동안 굳건한 점유율을 자랑했던 VSCode의 개발환경에서
                      새롭게 나타난 CURSOR가 점유율을 위협하고 있습니다.
                      이제 프로그래밍 공부도 트렌디하게!

                      01CS에서 비전공자, 일반인도 가능한 수준의
                      파이썬 프로그래밍 강의를 CURSOR를 사용해서 진행합니다.

                      언제까지 AI기술을 외면하실건가요?,
                      이제 더 이상 프로그래밍은 개발자의 전유물이 아닙니다!
                      현대사회에 유리한 생존을 위한 스킬을 배워보세요! 😎`,
        modalExtraInfo: '강의 시작하기 〉',
        modalLink: '/lms/course/python-cursor',
        openInNewTab: true
      },
    },
    {
      tileId: 'math-cs-beginner',
      status: ['dev', 'course'],
      img: bestBack06,
      title1: '비전공자, 청소년에게 강력추천 🚀',
      title2: '00MATH + 01CS',
      description: '수학의 개념과 프로그래밍을 동시에.',
      title1Color: '#D00070',
      title2Color: '#fff',
      descriptionColor: '#D00070',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '사칙연산부터 미분까지!',
        modalDetails: `00MATH와 01CS의 첫번째 콜라보 교육🥂
                      수학과 프로그래밍을 동시에 배웁니다.
                      사칙연산부터 미분까지 수학의 기본 개념을 배우고,
                      이를 프로그래밍으로 구현해봅니다.

                      '왜' 수학을 배워야 하는지,
                      대체 컴퓨터 과학과 수학은 무슨 연관이 있는지,
                      우리가 배우는 수학을 대체 어디에 써먹는지,
                      인공지능 개발자가 되기 위해서 수학공부를 어떻게 해야하는지
                      낱낱이 파헤쳐드립니다. 

                      수학과 관련된 다양한 알고리즘 문제는
                      매주 과제로 제공해드립니다.`,
        modalExtraInfo: '강의 시작하기 〉',
        // modalLink: '/courses/cursor-python'
      },
    },
    {
      tileId: 'ai-ms-office-beginner',
      status: ['dev', 'course'],
      img: bestBack01,
      title1: '코딩을 할 줄 몰라도 시작가능 🔥',
      title2: '생성형AI + MS 오피스',
      description: 'GPT, Copilot으로 실무역량 키우기',
      title1Color: '#000',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '자격증이 필요한 시대는 끝났다! 👻',
        modalDetails: `앞으로는 엑셀 함수 외우기, 모든 반복업무 등
                      기본적인 업무는 인공지능이 대체합니다.
                      그리고 지금까지 내가 할 수 없었던 작업까지 할 수 있어야 합니다.
                      목표를 정확하게 정하고, 과정을 논리적으로 생각하는 방법을 연습하면서
                      나의 학업이나 실무에 그대로 적용시켜 보세요.

                      생산성 극대화🔥
                      효율적인 업무처리🔥
                      매크로, 자동화🔥

                      생성형 AI를 활용해서 현대사회의 경쟁력을 높여보세요!`,
        modalExtraInfo: '강의 시작하기 〉',
        // modalLink: '/courses/gpt-copilot'
      },
    },
    {
      tileId: 'computer-architecture-beginner-school',
      status: ['dev', 'course'],
      img: bestBack08,
      title1: '정보과목 정복하기 🔥',
      title2: 'SW교육 의무화, 정보과목',
      description: '청소년을 위한 컴퓨터 구조 파헤치기',
      title1Color: '#000',
      title2Color: '#000',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: 'SW교육 의무화, 정보과목 정복하기 👻',
        modalDetails: `앞으로는 엑셀 함수 외우기, 모든 반복업무 등
                      기본적인 업무는 인공지능이 대체합니다.
                      그리고 지금까지 내가 할 수 없었던 작업까지 할 수 있어야 합니다.
                      목표를 정확하게 정하고, 과정을 논리적으로 생각하는 방법을 연습하면서
                      나의 학업이나 실무에 그대로 적용시켜 보세요.

                      생산성 극대화🔥
                      효율적인 업무처리🔥
                      매크로, 자동화🔥

                      생성형 AI를 활용해서 현대사회의 경쟁력을 높여보세요!`,
        modalExtraInfo: '강의 시작하기 〉',
        // modalLink: '/courses/gpt-copilot'
      },
    },
    {
      tileId: 'computer-architecture-beginner',
      status: ['dev', 'course'],
      img: bestBack07,
      title1: '바닥부터 시작하는 컴퓨터 이야기 💻',
      title2: '컴퓨터 구조론',
      description: `엔디비아가 하드웨어 회사라구요?`,
      title1Color: '#000',
      title2Color: '#000',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '누구나 배울 수 있는 컴퓨터 구조론 👻',
        modalDetails: `01CS의 컴퓨터 구조론에서는
                      단순히 CPU, GPU 장치에 대한 개념뿐만 아니라,
                      밑바닥부터 코드가 어떻게 실행되는지 알려드립니다.

                      우리의 이야기를 따라오다보면,
                      어느새 컴퓨터 구조를 이해하게 될 것입니다.

                      컴퓨터 구조를 이해하면
                      왜 엔디비아가 이렇게나 성장하는지 알 수 있습니다.

                      그리고 프로그래밍 언어를 정복하는데
                      아주 큰 힘이 될 것 입니다.`,
        modalExtraInfo: '강의 신청하기 〉',
        // modalLink: '/courses/gpt-copilot'
      },
    },
  ],
  starterTiles: [
    {
      tileId: 'memory-test',
      status: ['new', 'test'],
      img: starterImage01,
      title1: '무료 역량테스트',
      title2: '기억력 테스트 🧠',
      description: '공학자 기초 역량평가 테스트!',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '개발자에게 기억력은 왜 중요할까요?',
        modalDetails: `1. 코드 구조 기억하기:
                      복잡한 프로그램을 만들 때,
                      전체 구조를 머릿속에 그리며 작업해야 합니다.

                      2. 버그 찾기:
                      오류를 해결할 때,
                      여러 부분을 동시에 고려해야 합니다.

                      3. 멀티태스킹:
                      여러 작업을 동시에 처리하는 능력이 필요합니다.

                      42서울, 42파리 같은 유명한 IT교육기관에서도
                      유사한 기억력 테스트를 진행합니다.
                      왜냐구요?
                      개발자의 기본 역량이라고 생각하기 때문이죠.

                      우리의 두뇌도 근육과 같아서,
                      계속 훈련하지 않으면 그 능력이 줄어듭니다.
                      이 테스트로 여러분의 현재 기억력을 확인하고,
                      꾸준히 향상시켜 보세요!`,
        modalExtraInfo: '테스트 시작하기 〉',
        modalLink: '/lms/memory-test',
        openInNewTab: true
      },
    },
    {
      tileId: 'algorithm-test-beginner',
      status: ['dev', 'test'],
      img: starterImage03,
      title1: '개발자 기초 역량 강화',
      title2: '알고리즘 테스트',
      description: '',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      backgroundColor: '#00069A',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '알고리즘 테스트',
        modalDetails: '개발자의 알고리즘 능력을 테스트합니다. 다양한 알고리즘 문제를 풀어보세요.',
        modalExtraInfo: '준비중입니다',
      },
    },
    {
      tileId: 'mathematical-ability-test-beginner',
      status: ['dev', 'test'],
      img: starterImage02,
      title1: '무료 역량테스트',
      title2: '수리력 테스트 LEVEL1',
      description: '',
      title1Color: '#000',
      title2Color: '#000',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '수리력 테스트 LEVEL1',
        modalDetails: '개발자의 기본적인 수리 능력을 테스트합니다. 간단한 수학 문제를 풀어보세요.',
        modalExtraInfo: '준비중입니다',
      },
    },
  ],
  forDevTiles: [
    {
      tileId: 'python-data-structure-beginner',
      status: ['closed', 'ing', 'course'],
      img: bestBack02,
      title1: '개발자를 꿈꾸는 학습자를 위한 ⭐️',
      title2: '파이썬 + 자료구조 기초과정',
      description: '컴퓨터과학 전공수업 A+ 수준까지',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '코드를 시각화하여 프로그래밍 이해하기 🧠',
        modalDetails: `단순히 문법만 배우는 공부로는 인공지능 개발자로 성장하기 어렵습니다.
                      메모리를 시각화하여 내가 작성하는 코드가 어떻게 실행되는지 바닥부터 차근차근 설명해드립니다.
                      양질의 개발자로 성장하기 위한 발판을 단단하게 다져드립니다.

                      지난 9년간 쌓아온 강의경력을 바탕으로 구성된 01CS의 파이썬 교육은
                      유튜브, 책을 보면서 공부할때는 들을 수 없는 IT기술동향과 개발자에 대한 이야기도 함께 들려드립니다.

                      공학계열에 관심있는 비전공자, 청소년도 들을 수 있는 수준으로
                      대학교 전공수업 A+ 수준을 목표로 교육이 진행됩니다.
                      매주 알고리즘 과제도 있습니다. 😎`,
        modalExtraInfo: '강의 신청하기 〉',
        // modalLink: '/courses/python-basics'
      },
    },
    {
      tileId: 'openai-api-python-begineer',
      status: ['closed', 'ing', 'course'],
      img: bestBack03,
      title1: 'OpenAI API 정복하기 ⛰️',
      title2: 'AI 서비스 개발 따라하기',
      description: 'API 사용법과 챗봇 개발 프로젝트',
      title1Color: '#00069A',
      title2Color: '#00069A',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '프로젝트 위주의 교육👾 서비스 개발까지🚀',
        modalDetails: `해당 강의는 파이썬 + 자료구조 기초과정을
                      수료한 학습자만 신청할 수 있습니다.

                      가장 많이 알려진 GPT모델을 사용하여,
                      다양한 프로젝트를 진행해보는 강의입니다.

                      OpenAI API를 사용하여
                      TTS, Finetune, Embedding, Chatbot 등
                      유튜브를 보고 따라하기 힘든 부분까지 알려드립니다.

                      코치가 함께 에러를 해결해주고,
                      원하는 서비스를 개발할 수 있도록 돕습니다.
                      `,
        modalExtraInfo: '강의 신청하기 〉',
        // modalLink: '/courses/ai-service-dev'
      },
    },
    {
      tileId: 'web-dev-beginner',
      status: ['closed', 'ing', 'course'],
      img: bestBack09,
      title1: '기획, GPT, Figma 기초 👾',
      title2: '웹 개발 따라하기',
      description: '바닥부터 시작하는 누구나 가능한 웹 개발',
      title1Color: '#027400',
      title2Color: '#027400',
      descriptionColor: '#027400',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: 'AI와 함께하는 웹 개발 입문 🌐',
        modalDetails: `소프트웨어의 기본 구조를 이해하고 AI 도구를 활용하여 
                      웹/앱 개발의 기초를 배웁니다. 
                      기획부터 디자인, 개발까지 전반적인 과정을 경험해볼 수 있습니다.

                      주요 학습 내용:
                      - 소프트웨어 구조의 이해
                      - 생성형 AI를 활용한 코드 생성 및 문제 해결
                      - Figma를 이용한 기본적인 UI/UX 디자인
                      - CURSOR 개발환경
                      - Firebase 서버/DB 사용
                      - HTML, CSS, JavaScript 기초
                      - React, TypeScript, Tailwind CSS 등 프레임워크 활용
                      - 간단한 웹 애플리케이션 제작 프로젝트

                      이 과정을 통해 AI 도구를 효과적으로 활용하여 
                      웹 개발의 기초를 쌓을 수 있습니다.`,
        modalExtraInfo: '강의 신청하기 〉',
        // modalLink: '/courses/ai-web-dev-basics'
      },
    },
  ],
  blockTiles: [
    {
      tileId: 'block-coding-test-beginner',
      status: ['dev', 'test'],
      img: blockImage01,
      title1: '초급자도 할 수 있는 블록코딩 테스트 Level1',
      title2: '제한시간 40분',
      description: '개발중입니다.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'h-[350px]',
      mobileWidth: 'w-[280px]',
      mobileHeight: 'h-[350px]',
      modalContent: {},
    },
    {
      tileId: 'block-coding-test-intermediate',
      status: ['dev', 'test'],
      img: blockImage02,
      title1: '조금 더 어려운 블록코딩 테스트 Level2',
      title2: '제한시간 1시간',
      description: '개발중입니다.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'h-[350px]',
      mobileWidth: 'w-[280px]',
      mobileHeight: 'h-[350px]',
      modalContent: {},
    },
    {
      tileId: 'block-coding-test-advanced',
      status: ['dev', 'test'],
      img: blockImage02,
      title1: '극악의 난이도 블록코딩 테스트 Level3',
      title2: '제한시간 2시간',
      description: '개발중입니다.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'h-[350px]',
      mobileWidth: 'w-[280px]',
      mobileHeight: 'h-[350px]',
      modalContent: {},
    },
  ],
  moduleTiles: [
    {
      tileId: 'for-starter',
      status: ['dev', 'forStarter'],
      img: forStarter,
      title1: '',
      title2: '',
      description: '',
      title1Color: '#ffd400',
      title2Color: '#ffd400',
      descriptionColor: '#ffd400',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '',
        modalDetails: '',
        modalExtraInfo: '',
        moduleType: '',
        openInNewTab: true
      },
    },
    {
      tileId: 'pythonLauguageBeginner',
      status: ['dev', 'module'],
      img: module_py_begin,
      title1: '파이썬 기초',
      title2: 'Python Basics',
      description: '기초 파이썬 프로그래밍을 시작하세요.',
      title1Color: '#ffd400',
      title2Color: '#ffd400',
      descriptionColor: '#ffd400',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '파이썬 기초 교육 콘텐츠',
        modalDetails: '적응형 학습을 위한 데이터 분류/라벨링 작업과 문제 개발, 문제 디자인까지 작업중입니다. 예시로 올려놓은 문제로 아직 난이도, 풀이방법 등 완전히 구현되지 않았습니다.',
        modalExtraInfo: '커리큘럼 보기 〉',
        moduleType: 'python-beginner',
        openInNewTab: true
      },
    },
    {
      tileId: 'softwareEngineeringBeginner',
      status: ['dev', 'module'],
      img: module_sw_begin,
      title1: '서비스 기획자가 반드시 알아야할',
      title2: 'Software Engineering',
      description: '소프트웨어의 생명주기에 대하여',
      title1Color: '#000',
      title2Color: '#fff',
      descriptionColor: '#000',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '소프트웨어 공학 교육 콘텐츠',
        modalDetails: '적응형 학습을 위한 데이터 분류/라벨링 작업과 문제 개발, 문제 디자인까지 작업중입니다. 예시로 올려놓은 문제로 아직 난이도, 풀이방법 등 완전히 구현되지 않았습니다.',
        modalExtraInfo: '커리큘럼 보기 〉',
        moduleType: 'software-engineering',
        openInNewTab: true
      },
    },
    {
      tileId: 'dataStructureBeginner',
      status: ['dev', 'module'],
      img: module_data_begin,
      title1: '자료구조 기초',
      title2: 'Data Structure Basics',
      description: '데이터 구조를 이해하고 활용하세요.',
      title1Color: '#D00070',
      title2Color: '#D00070',
      descriptionColor: '#D00070',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '자료구조 기초 교육 콘텐츠',
        modalDetails: '적응형 학습을 위한 데이터 분류/라벨링 작업과 문제 개발, 문제 디자인까지 작업중입니다. 예시로 올려놓은 문제로 아직 난이도, 풀이방법 등 완전히 구현되지 않았습니다.',
        modalExtraInfo: '문제 풀기 〉',
        moduleType: 'data-structures-beginner',
        openInNewTab: true
      },
    },
    {
      tileId: 'computerArchitectureBeginner',
      status: ['dev', 'module'],
      img: module_computer_begin,
      title1: '컴퓨터구조 기초',
      title2: 'Computer Architecture Basics',
      description: '컴퓨터 구조를 이해하면 코드가 달라집니다.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '컴퓨터 구조 기초 교육 콘텐츠',
        modalDetails: '적응형 학습을 위한 데이터 분류/라벨링 작업과 문제 개발, 문제 디자인까지 작업중입니다. 예시로 올려놓은 문제로 아직 난이도, 풀이방법 등 완전히 구현되지 않았습니다.',
        modalExtraInfo: '문제 풀기 〉',
        moduleType: 'computer-architecture-beginner',
        openInNewTab: true
      },
    },
    {
      tileId: 'promptEngineeringBeginner',
      status: ['dev', 'module'],
      img: module_computer_begin,
      title1: '생성형 AI를 시작하기 위한 정석',
      title2: '프롬프트 작성의 정석',
      description: '이제 모든 반복작업을 없애야 합니다.',
      title1Color: '#fff',
      title2Color: '#fff',
      descriptionColor: '#fff',
      width: 'md:w-[400px]',
      height: 'md:h-[500px]',
      mobileWidth: 'w-[310px]',
      mobileHeight: 'h-[450px]',
      modalContent: {
        modalTitle: '개발중',
        modalDetails: '',
        modalExtraInfo: '학습 시작 〉',
        moduleType: 'prompt-engineering-beginner',
        openInNewTab: true
      },
    },
  ]
};