import React, { useState } from 'react';

const PayPage = () => {
  const [monthlyPrice] = useState(14900);
  const yearlyPrice = Math.round((monthlyPrice * 12 * 0.6)); // 40% 할인

  return (
    <div className="max-w-[1440px] mx-auto flex items-center justify-center pt-24 p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* 1개월 카드 */}
        <div className="border border-gray-300 rounded-lg w-[350px] h-[450px] md:w-[450px] md:h-[500px]">
          <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
            <div className="mt-4">
              <p className="text-2xl font-bold">1개월 학습권</p>
              <p className="text-[#C83232] text-4xl font-bold mt-2">{monthlyPrice.toLocaleString()}<span className="text-sm text-black">원</span></p>
            </div>
            <div className="py-4">
              <button className="w-full font-semibold border border-[#C83232] bg-[#fff] text-[#C83232] px-4 py-2 rounded-md hover:bg-[#C83232] hover:text-white transition-colors duration-300 text-md">
                1개월 학습권 이용하기
              </button>
            </div>
          </div>
          <div className="h-[35%] p-6 font-semibold text-sm leading-[28px]">
            1개월 동안 자유롭게 이용하실 수 있습니다.
          </div>
        </div>

        {/* 12개월 카드 */}
        <div className="border border-[#D00070] rounded-lg w-[350px] h-[450px] md:w-[450px] md:h-[500px] mb-24">
          <div className="h-[65%] bg-[#d00070]/10 p-4 flex flex-col justify-between">
            <div className="mt-4">
              <p className="text-2xl font-bold">12개월 학습권</p>
              <p className="text-[#D00070] text-4xl font-bold mt-2">{yearlyPrice.toLocaleString()}<span className="text-sm text-black">원 (40% 할인)</span></p>
            </div>
            <div className="py-4">
              <button className="w-full font-semibold bg-[#D00070] text-white px-4 py-2 rounded-md hover:bg-[#d00070]/80 transition-colors duration-300 text-md">
                12개월 학습권 이용하기
              </button>
            </div>
          </div>
          <div className="h-[35%] p-6 font-semibold text-sm leading-[28px]">
            12개월 동안 무제한으로 이용하실 수 있습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayPage;