// Main.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EmojiGroup from '../emoji/EmojiGroup';
import ContentsModal from '../modal/ContentsModal';
import { VERSION } from '../../../version/Version';

const Main = ({ buttonStyle }) => {
  const user = useSelector((state) => state.auth.user); // state.auth.user는 직렬화된 데이터
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [openInNewTab, setOpenInNewTab] = useState(false);

  const modalContents = {
    slopex: {
      modalTitle: '세상에 필요한 기초공학교육 🌏',
      modalDetails: `SlopeXcelerity는 세상을 변화시키는
      위대한 공학을 위해 다양한 프로젝트를 진행합니다.
                    `,
      modalExtraInfo: '기초공학교육 살펴보기',
      // modalLink: '/developer-course'
    },
    developer: {
      modalTitle: '세상을 변화시키는 공학자',
      modalDetails: `우리는 비전공자, 청소년도 가능한 기초공학교육을 제공합니다.
                    01CS는 텍스트 코딩을 배울 수 있는 다양한 교육 콘텐츠를 개발합니다.
                    
                    너무 어린 친구들이 텍스트 코딩을 시작하는건 굉장히 신중해야합니다.
                    섣부르게 시작했다가, 프로그래밍에 대한 흥미를 잃는 경우가 많습니다.

                    프로그래밍에 대한 이해는 미래산업에 필요한 기본 역량이 될 것이기에,
                    우리는 이것을 굉장히 경계하고 있습니다.

                    그러니 억지로 아이들에게 프로그래밍을 배우도록 강요하진 말아주세요.
                    공학자는 정말 멋지고 위대한 직업이라는걸 보여주고 싶습니다.
                    `,
      modalExtraInfo: '기초공학교육 살펴보기',
      // modalLink: '/developer-course'
    },
    coach: {
      modalTitle: '목표까지 달려갈 수 있도록 🏃🏻‍♂️‍➡️',
      modalDetails: `강력한 동기부여를 제공할 수 있는
                    다양한 장치를 개발중입니다.
                    
                    Sloper는 학습자의 모든 질문에 대한 답변을 제공합니다.
                    
                    놀랍게도!
                    우리는 AI가 아닙니다.
                    Sloper는 사람입니다.
                    `,
      modalExtraInfo: '아직 담당 Sloper가 없다면? 👻',
      modalLink: '/lms/find-sloper'
    }
  };

  const openModal = (modalType) => {
    setModalContent(modalContents[modalType]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onEmojiClick = (label) => {
    const emojiModalContent = {
      '00MATH': {
        modalTitle: '00MATH',
        modalDetails: '공학교육에 필요한 기초수학 과정입니다.',
        modalExtraInfo: '자세히 보기',
      },
      '01CS': {
        modalTitle: '01CS',
        modalDetails: '비전공자, 청소년도 가능한 기초 컴퓨터 과학 과정입니다.',
        modalExtraInfo: '자세히 보기',
        modalLink: '/'
      },
      '19Go': {
        modalTitle: '19Go',
        modalDetails: `두고팡과 함께 바둑을 배워보세요!
                      어린 친구들도 스스로 바둑을 배울 수 있도록 도와드립니다.
                      다양한 바둑 콘텐츠를 경험해보세요`,
        modalExtraInfo: '두고팡 바로가기',
        modalLink: 'https://dogopang.com'
      }
      // 다른 이모지에 대한 모달 내용을 여기에 추가하세요.
    };

    if (emojiModalContent[label]) {
      setModalContent(emojiModalContent[label]);
      setIsModalOpen(true);
      setOpenInNewTab(label === '19Go'); // 19Go인 경우에만 새 창에서 열기
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center px-[8%] mt-[15%]">
        <div className="max-w-[1440px] w-full flex flex-col md:flex-row justify-between">
          {/* 왼쪽 텍스트 */}
          <div className="flex flex-col mb-8 mt-24 md:mt-0 md:mb-0 md:mr-4">
            <h1 className="text-2xl md:text-4xl font-bold text-gray-900">
              {/* <span className="text-base">1.0.0-alpha.1</span> */}
              <span className="text-1xl md:text-2xl mb-2 block">AI 시대에 필요한 기초공학교육</span>
              <span className="text-[#D00070]">01CS 컴퓨터과학</span> 🚩
            </h1>
            <h2 className="text-xl md:text-xl font-bold text-gray-900 mt-4 md:mt-6">
              To provide foundational engineering education.{' '}
              <span className="inline-block">
                <span role="img" aria-label="gear">⚙️</span>
              </span>
              {/* <br/> <span className="text-base text-[#D00070]"><span className="block md:inline mt-4">청소년, 비전공자에게</span>  </span>🏗️ */}
            </h2>

            {/* 로그인된 사용자에게만 버튼 표시 */}
            {/* {user && (
              <a
                href="/lms/more/myinfo"
                target="_blank"
                rel="noopener noreferrer"
                className={`bg-[#D00070] text-[#fff] border-[#D00070] text-center font-extrabold mt-6 py-2 px-5 rounded-full border-2 border-transparent transition duration-300 ${buttonStyle}`}
              >
                더 나은 학습을 위한, 학습 경험 입력하기 🚀
              </a>
            )} */}
          </div>

          {/* 오른쪽 텍스트 */}
          <div className="flex flex-col mt-8 md:mt-0 text-right md:text-right">
            <p className="text-gray-600 mb-1">버전정보 : {VERSION}</p>
            <a href="##" onClick={(e) => { e.preventDefault(); openModal('slopex'); }} className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6 cursor-pointer">열심히 개발중입니다. 🏗️</a>

            <p className="text-gray-600 mb-1">13세 이상부터 추천합니다</p>
            <a href="##" onClick={(e) => { e.preventDefault(); openModal('developer'); }} className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6 cursor-pointer">세상을 변화시키는 공학자로 🧑🏻‍💻</a>

            <p className="text-gray-600 mb-1">러닝메이트가 되어드립니다</p>
            <a href="##" onClick={(e) => { e.preventDefault(); openModal('coach'); }} className="text-[#D00070] font-bold hover:underline mb-4 md:mb-6 cursor-pointer">도와주세요! Sloper! 👻</a>
          </div>
        </div>
      </div>

      {/* EmojiGroup 컴포넌트로 변경 */}
      <EmojiGroup onEmojiClick={onEmojiClick} />

      {/* ContentsModal 컴포넌트 */}
      <ContentsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        modalContent={modalContent}
        openInNewTab={openInNewTab}
      />
    </div>
  );
};

export default Main;