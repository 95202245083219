import React from 'react';
import { pythonCurriculum, softwareEngineeringCurriculum, dataStructuresCurriculum, computerArchitectureCurriculum } from '../../../../../data/curriculumData';
import { pythonLanguageBeginner } from '../../../../../data/pythonLanguageBeginner';
import { softwareEngineeringProblems } from '../../../../../data/softwareEngineeringBeginner';
import { dataStructureProblems } from '../../../../../data/dataStructureBeginner';
import { computerArchitectureProblems } from '../../../../../data/computerArchitectureBeginner';

export function getProblemsForCurriculum(curriculumId) {
  let curriculum;
  let problemSet;

  if (curriculumId.startsWith('python')) {
    curriculum = pythonCurriculum.find(c => c.id === curriculumId);
    problemSet = pythonLanguageBeginner;
  } else if (curriculumId === 'software-engineering') {
    curriculum = softwareEngineeringCurriculum.find(c => c.id === curriculumId);
    problemSet = softwareEngineeringProblems;
  } else if (curriculumId === 'data-structures-beginner') {
    curriculum = dataStructuresCurriculum.find(c => c.id === curriculumId);
    problemSet = dataStructureProblems;
  } else if (curriculumId === 'computer-architecture-beginner') {
    curriculum = computerArchitectureCurriculum.find(c => c.id === curriculumId);
    problemSet = computerArchitectureProblems;
  }

  if (!curriculum) return [];

  const curriculumProblems = curriculum.chapters.flatMap(chapter => 
    chapter.topics.flatMap(topic => 
      problemSet.filter(problem => 
        problem.subTopic === topic || problem.topic.toLowerCase() === chapter.title.toLowerCase()
      )
    )
  );

  return curriculumProblems;
}

export function getProblemsForChapter(curriculumId, chapterId) {
  if (!curriculumId || !chapterId) {
    return [];
  }

  let curriculum;
  let problemSet;

  if (curriculumId.startsWith('python')) {
    curriculum = pythonCurriculum.find(c => c.id === curriculumId);
    problemSet = pythonLanguageBeginner;
  } else if (curriculumId === 'software-engineering') {
    curriculum = softwareEngineeringCurriculum.find(c => c.id === curriculumId);
    problemSet = softwareEngineeringProblems;
  } else if (curriculumId === 'data-structures-beginner') {
    curriculum = dataStructuresCurriculum.find(c => c.id === curriculumId);
    problemSet = dataStructureProblems;
  } else if (curriculumId === 'computer-architecture-beginner') {
    curriculum = computerArchitectureCurriculum.find(c => c.id === curriculumId);
    problemSet = computerArchitectureProblems;
  } else {
    console.error('Unknown curriculum type:', curriculumId);
    return [];
  }

  if (!curriculum) {
    return [];
  }

  const chapter = curriculum.chapters.find(ch => ch.id === chapterId);
  if (!chapter) {
    return [];
  }

  const chapterProblems = problemSet.filter(problem => 
    chapter.topics.includes(problem.topic) ||
    chapter.topics.includes(problem.subTopic) ||
    chapter.title.toLowerCase().includes(problem.topic.toLowerCase())
  );

  return chapterProblems;
}

const CurriculumManager = () => {
  return (
    <div>
    </div>
  );
};

export default CurriculumManager;