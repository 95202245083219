import React from 'react';
import { useNavigate } from 'react-router-dom';
import { statusStyles } from '../../../landing/container/contents/tileComponents/Tile';

const ClassResults = ({ tiles }) => {
  const navigate = useNavigate();

  if (!tiles || tiles.length === 0) {
    return <p className="w-full md:w-[75%] mx-auto px-[3%]">표시할 강의가 없습니다.</p>;
  }

  const handleStartLearning = (tile) => {
    if (tile.modalContent && tile.modalContent.moduleType) {
      navigate(`/lms/curriculum/${tile.modalContent.moduleType}`);
    } else {
      console.error(`Module type not found for tile:`, tile);
      navigate('/lms/curriculum');
    }
  };

  return (
    <div className="w-full md:w-[90%] mx-auto px-[3%] py-8 md:py-16">
      <ul className="list-none flex flex-wrap -mx-3">
        {tiles.map((tile, index) => (
          <li key={index} className="w-full md:w-1/3 px-3 mb-6">
            <div className="relative h-80 rounded-lg shadow-md hover:shadow-lg transition-shadow overflow-hidden">
              <img src={tile.img} alt={tile.title1} className="w-full h-full object-cover" />
              <div className="absolute inset-0 bg-black bg-opacity-20 p-4 flex flex-col justify-between">
                <div>
                  <div className="mb-2 flex flex-wrap">
                    {Array.isArray(tile.status) ? tile.status.map((stat, idx) => (
                      statusStyles[stat] && (
                        <span key={idx} className={`${statusStyles[stat].bgColor} ${statusStyles[stat].textColor} text-xs font-bold mr-2 mb-1 px-2 py-1 rounded`}>
                          {statusStyles[stat].text}
                        </span>
                      )
                    )) : null}
                  </div>
                  <h3 className="text-sm font-bold text-white">{tile.title1}</h3>
                  <h4 className="text-lg font-extrabold mb-2 text-white">{tile.title2}</h4>
                  <p className="text-sm mb-3 text-gray-200">{tile.description}</p>
                </div>
                <div>
                  <button 
                    className="bg-[#d00070] hover:bg-[#d00070]/80 text-white font-bold py-2 px-4 rounded text-sm w-full"
                    onClick={() => handleStartLearning(tile)}
                  >
                    학습 시작
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClassResults;
