
import data_basics_001 from '../assets/problem/data_basics_001.webp';
import data_basics_002 from '../assets/problem/data_basics_002.webp';
import data_basics_003 from '../assets/problem/data_basics_003.webp';
import data_basics_010 from '../assets/problem/data_basics_010.png';
import programming_fundamentals_003 from '../assets/problem/python-programming-fundamentals_003.png';
import programming_fundamentals_006 from '../assets/problem/python-programming-fundamentals_006.png';
import programming_fundamentals_008 from '../assets/problem/python-programming-fundamentals_008.png';
import programming_fundamentals_009 from '../assets/problem/python-programming-fundamentals_009.png';
import basic_data_001 from '../assets/problem/basic-data-structure_001.png';
import basic_data_002 from '../assets/problem/basic-data-structure_002.png';
import basic_data_003 from '../assets/problem/basic-data-structure_003.png';
import basic_data_004 from '../assets/problem/basic-data-structure_004.png';
import basic_data_005 from '../assets/problem/basic-data-structure_005.png';
import basic_data_006 from '../assets/problem/basic-data-structure_006.png';
import basic_data_007 from '../assets/problem/basic-data-structure_007.png';
import operators_001 from '../assets/problem/operators_001.png';
import operators_002 from '../assets/problem/operators_002.png';
import operators_003 from '../assets/problem/operators_003.png';
import operators_004 from '../assets/problem/operators_004.png';
import operators_005 from '../assets/problem/operators_005.png';

export const pythonLanguageBeginner = [
  //id: "data-types-variables"
  //title: "데이터 종류와 가장 기본적인 저장방법"
  //topics: ["Programming Fundamentals", "Data Types", "Integers",
  //         "Floats", "Strings", "Booleans", "Variables"]
  ////////////////////////////////


  // data-types-variables_001 // Programming Fundamentals // multiple_choice - 데이터
  {
    problemId: "data-types-variables_001",
    image: data_basics_001,
    title: "데이터",
    description: "데이터는 모든 프로그램의 핵심이라고 할 수 있습니다. 간단한 계산기 프로그램도 숫자 데이터를 처리하죠.",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Programming Fundamentals",
    subTopic: "Data",
    difficulty: 1,
    difficultyDescription: "데이터 첫 시작 질문",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner"
    ],
    programmingConcepts: ["데이터 타입", "변수"],
    skillsPracticed: ["기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "데이터의 개념을 이해한다."
    ],
    commonMistakes: [
      "데이터와 물리적 객체의 혼동"
    ],
    hints: {
      beginner: [
        "데이터는 정보를 컴퓨터가 이해할 수 있는 형태로 표현한 것입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "변수"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 데이터는 다양한 형태로 표현됩니다 (예: 숫자, 문자열 등)."]
      }
    },
    choices: [
      { id: "A", text: "컴퓨터가 이해할 수 있도록 표현된 정보나 사실" },
      { id: "B", text: "컴퓨터의 하드웨어 부품 중 하나" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. 데이터는 컴퓨터가 이해하고 처리할 수 있는 정보나 사실을 의미합니다. 하드웨어 부품은 데이터가 아니라 물리적인 구성 요소입니다.",
    examples: [
      {
        input: "age = 25",
        output: "25"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-09",
    lastUpdated: "2024-10-09",
    versionNumber: 1.0
  },

  // data-types-variables_002 // Programming Fundamentals // concept - 데이터의 중요성
  {
    problemId: "data-types-variables_002",
    type: "concept",
    title: "데이터의 중요성",
    image: null,
    description: `
      프로그래밍에서 데이터는 프로그램이 처리하는 모든 정보를 의미합니다.
      
      주요 데이터 타입은 다음과 같습니다.
        - 정수(Integer): 소수점이 없는 숫자 (예: 1, -5, 100)
        - 실수(Float): 소수점이 있는 숫자 (예: 3.14, -0.01)
        - 문자열(String): 텍스트 데이터 (예: "Hello, World!")
        - 불리언(Boolean): 참(True) 또는 거짓(False)
    `,
    subject: "Computer Science",
    topic: "Programming Fundamentals",
    subTopic: "Data Basics",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Data", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Data Structures", "Algorithms"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-15",
    lastUpdated: "2024-10-15",
    versionNumber: 1.0
  },

  // data-types-variables_003 // Data Types // multiple_choice - 정수(Integer) 데이터 타입
  {
    problemId: "data-types-variables_003",
    image: null,
    title: "정수(Integer) 데이터 타입",
    description: "다음 중 Python에서 정수(integer) 데이터 타입을 올바르게 나타내는 것은 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Integer",
    difficulty: 2,
    difficultyDescription: "기본 데이터 타입을 이해하는 초급 수준의 질문입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분류"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Integer",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "정수"],
    skillsPracticed: ["데이터 타입 식별", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python의 기본 데이터 타입 중 정수형 이해",
      "다양한 데이터 타입의 표현 방식 구분"
    ],
    commonMistakes: [
      "부동 소수점 숫자와 정수의 혼동",
      "문자열으로 표현된 숫자와 실제 정수의 혼동",
      "불리언 값과 정수의 혼동"
    ],
    hints: {
      beginner: [
        "정수는 소수점이 없는 숫자입니다.",
        "Python에서 정수는 별도의 표시 없이 그냥 숫자로 표현됩니다."
      ],
      intermediate: [
        "다른 데이터 타입과의 차이점을 생각해보세요.",
        "각 보기의 값이 어떤 데이터 타입을 나타내는지 고려해보세요."
      ],
      advanced: [
        "Python에서 type() 함수를 사용하면 데이터의 타입을 확인할 수 있습니다.",
        "정수형 외의 다른 숫자 타입에 대해서도 생각해보세요."
      ]
    },
    relatedConcepts: ["부동 소수점", "문자열", "불리언", "타입 변환"],
    learningStyle: ["시각적 학습", "논리적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 정수는 int 타입으로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "42" },
      { id: "B", text: "42.0" },
      { id: "C", text: "'42'" },
      { id: "D", text: "True" },
      { id: "E", text: "int(42)" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. Python에서 정수는 소수점 없이 그냥 숫자로 표현됩니다. '42'는 순수한 정수형 데이터입니다. B는 부동 소수점 숫자, C는 문자열, D는 불리언 값, E는 정수를 반환하는 함수 호출입니다.",
    examples: [
      {
        input: "type(42)",
        output: "<class 'int'>"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: "python_intro_001"
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-07",
    lastUpdated: "2024-10-07",
    versionNumber: 1.0
  },

  // data-types-variables_004 // Data Types // code - 변수 선언 및 초기화
  {
    problemId: "data-types-variables_004",
    image: data_basics_002,
    title: "데이터를 저장하는 방법",
    description: `데이터를 저장하기 위해서는 변수라고 불리는 저장공간이 필요합니다.
    프로그래밍에서 변수는 1개의 데이터를 저장하는 공간을 의미합니다.
    
    선언과 동시에 데이터를 저장하는 것을 의미합니다.

    'a'라는 변수를 선언하고, 42라는 정수로 초기화하는 방법은 다음과 같습니다.
    프로그래밍에서 초기화는 0을 만든는다는 의미가 아닙니다.

    [Code]
    a = 42

    아래에서 그대로 따라서 작성후 🚩 Run 버튼을 눌러보세요.`,

    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Variables",
    subTopic: "Initialization",
    difficulty: 1,
    difficultyDescription: "변수를 사용하여 데이터를 관리하는 기본적인 개념을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Variables",
      "Data Management"
    ],
    programmingConcepts: ["변수", "데이터 저장", "출력"],
    skillsPracticed: ["변수 초기화", "값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "변수의 개념 이해하기",
      "변수를 사용하여 데이터를 효율적으로 관리하는 방법 이해하기"
    ],
    commonMistakes: [
      "변수를 문자열로 초기화하는 실수",
      "변수명을 잘못 사용하는 경우"
    ],
    hints: {
      beginner: [
        "변수는 값을 저장할 수 있는 이름입니다. 변수에 값을 저장하려면 '=' 연산자를 사용하세요.",
        "print() 함수를 사용하여 변수의 값을 출력할 수 있습니다."
      ],
      intermediate: [
        "변수명은 의미 있는 이름을 사용하는 것이 좋습니다."
      ],
      advanced: [
        "다른 데이터 타입과 변수를 결합하여 더 복잡한 데이터를 관리할 수 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있습니다."]
      }
    },
    initialCode: `# 아래에 변수 a를 선언하고, 42라는 정수로 초기화하는 코드를 작성해보세요.


# 변수 a의 값을 출력하는 코드입니다. 정수 42가 출력되어야 합니다.
print(a)`,
    testCases: [
      {
        input: "",
        expected: "42"
      }
    ],
    solution: `def initialize_variable():
      a = 42
      print(a)
  
  initialize_variable()`,
    examples: [
      {
        input: ``,
        output: "42"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.15
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-04-27",
    lastUpdated: "2024-04-27",
    versionNumber: 1.0
  },

  // data-types-variables_005 // Data Types // multiple_choice - 문자열(String) 데이터 타입
  {
    problemId: "data-types-variables_005",
    image: null,
    title: "문자열(String) 데이터 타입",
    description: "변수 word에 문자열(String) 데이터 타입을 올바르게 선언하고 초기화하는 코드는 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "문자열 데이터 타입의 기본적인 사용법을 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "문자열"],
    skillsPracticed: ["문자열 선언", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 문자열 데이터 타입 이해",
      "문자열을 변수에 할당하는 방법 학습"
    ],
    commonMistakes: [
      "따옴표 없이 문자열을 선언함",
      "문자열을 감싸는 따옴표의 혼동",
      "문자열 내에 이스케이프 문자를 잘못 사용함"
    ],
    hints: {
      beginner: [
        "문자열은 따옴표(' 또는 \")로 감싸서 선언합니다.",
        "작은 따옴표, 큰 따옴표 중 하나를 사용하면 됩니다."
      ],
    },
    relatedConcepts: ["변수", "따옴표", "이스케이프 문자"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 따옴표로 감싸서 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: `word = "123` },
      { id: "B", text: `word = Hello` },
      { id: "C", text: `word = 'Hello'` },
      { id: "D", text: `word = Hello'` },
      { id: "E", text: `word = 123` }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 문자열은 따옴표(' 또는 \")로 감싸서 선언해야 합니다.",
    examples: [
      {
        input: `word = 'Hello'`,
        output: `Hello`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-09",
    lastUpdated: "2024-10-09",
    versionNumber: 1.0
  },

  // data-types-variables_006 // memory_management // multiple_choice - 대소문자 구분
  {
    problemId: "data-types-variables_006",
    type: "multiple_choice",
    title: "대소문자 구분",
    image: basic_data_002,
    description: `
      파이썬에서는 대소문자를 구분합니다.
      이진법으로 변환해보면 확실하게 알 수 있습니다.
      지금 당장 이진법으로 변환하는 방법까진 몰라도 괜찮습니다.

      다음 중 문자 'a'와 'A'의 차이에 대한 설명으로 올바른 것은 무엇인가요?
      `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Strings",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "문자의 대소문자 구분에 대해 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분류"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Characters",
      "Case Sensitivity"
    ],
    programmingConcepts: ["문자열", "대소문자", "데이터 타입"],
    skillsPracticed: ["문자 비교", "문자열 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "파이썬에서 대소문자가 구분되는 이유 이해",
      "문자열 내 대소문자 구분 방법 학습"
    ],
    commonMistakes: [
      "대소문자를 구분하지 않고 문자를 비교",
      "문자열을 비교할 때 대소문자를 혼동"
    ],
    hints: {
      beginner: [
        "파이썬은 대소문자를 구분하므로 'a'와 'A'는 다른 문자로 인식됩니다.",
        "문자열 비교 시 대소문자가 일치해야 동일한 것으로 취급됩니다."
      ]
    },
    relatedConcepts: ["문자열", "문자 비교", "데이터 타입"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["파이썬에서는 대소문자를 구분합니다."]
      }
    },
    choices: [
      { id: "A", text: "'a'와 'A'는 동일한 문자이다." },
      { id: "B", text: "이진법으로 변환해보면 서로 다른 문자임을 알 수 있다." },
      { id: "C", text: "파이썬에서는 대소문자를 구분하지 않는다." }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 파이썬에서는 'a'와 'A'를 소문자와 대문자로 구분하여 다른 문자로 인식합니다.",
    examples: [
      {
        input: `
  a = 'a'
  A = 'A'
  print(a == A)
        `,
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // data-types-variables_007 // data-types // code - 대소문자 구분 확인하기
  {
    problemId: "data-types-variables_007",
    title: "대소문자 구분 확인하기",
    description: `파이썬에서 대문자와 소문자는 서로 다른 문자로 인식됩니다. 아래의 코드에서 변수 a와 A를 비교하여 그 차이를 확인하고, 결과가 True로 출력되는지 확인해보세요.

  **참고:** a와 A는 대소문자가 다르므로 비교 결과가 True가 되어야 합니다.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Strings",
    subTopic: "String Comparison",
    difficulty: 1,
    difficultyDescription: "대소문자 구분을 이해하고 비교하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "조건문"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Comparison",
      "Variables"
    ],
    programmingConcepts: ["문자열", "대소문자", "비교 연산"],
    skillsPracticed: ["문자 비교", "조건문 작성"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "파이썬에서 대소문자가 어떻게 구분되는지 이해하기",
      "문자열을 비교하는 방법 학습하기",
      "비교 연산자를 사용하여 조건문 작성하기"
    ],
    commonMistakes: [
      "대소문자를 구분하지 않고 비교",
      "비교 연산자를 잘못 사용"
    ],
    hints: {
      beginner: [
        "파이썬에서는 대문자와 소문자가 다른 문자로 취급됩니다.",
        "`==` 연산자를 사용하여 두 문자를 비교하세요.",
        "비교 결과를 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "비교 연산자", "조건문"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 대소문자는 구분됩니다."]
      }
    },
    initialCode: `# a와 A가 실제로 다른 문자인지 비교합니다.
# != 는 '같지 않다'는 뜻을 가진 비교 연산자입니다.
print('a' != 'A')
  `,
    testCases: [
      { input: "", expected: "True" }
    ],
    solution: `a = 'a'
  A = 'A'

  # a와 A가 다른지 비교하고 결과를 출력합니다.
  print(a != A)
  `,
    examples: [
      {
        input: "",
        output: "True"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // data-types-variables_008 // Data Types // multiple_choice - 불리언(Boolean) 데이터 타입
  {
    problemId: "data-types-variables_008",
    image: null,
    title: "불리언(Boolean) 데이터 타입",
    description: "불리언(Boolean) 데이터 타입을 올바르게 선언한 코드는 무엇인가요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Boolean",
    difficulty: 1,
    difficultyDescription: "불리언 데이터 타입의 기본적인 사용법을 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Boolean",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입", "변수", "불리언"],
    skillsPracticed: ["불리언 선언", "기본 문법 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 불리언 데이터 타입 이해",
      "불리언 값을 변수에 할당하는 방법 학습"
    ],
    commonMistakes: [
      "소문자로 불리언을 선언함",
      "숫자를 불리언으로 오인함",
      "불리언 값에 따옴표 사용"
    ],
    hints: {
      beginner: [
        "불리언 값은 대문자로 시작합니다.",
        "True와 False 두 가지 값만 존재합니다."
      ]
    },
    relatedConcepts: ["조건문", "논리 연산자"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 불리언은 True와 False로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: `is_active = true` },
      { id: "B", text: `is_active = True` },
      { id: "C", text: `is_active = "True"` },
      { id: "D", text: `is_active = 1` },
      { id: "E", text: `is_active = FALSE` }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 불리언 값은 첫 글자가 대문자인 True와 False로 표현됩니다. A는 소문자, C는 문자열, D는 정수, E는 대문자 FALSE는 잘못된 표현입니다.",
    examples: [
      {
        input: `is_active = True`,
        output: `True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-08",
    lastUpdated: "2024-10-08",
    versionNumber: 1.0
  },

  // data-types-variables_009 // Programming Fundamentals // concept - 낙타 표기법
  {
    problemId: "data-types-variables_009",
    image: data_basics_003,
    type: "concept",
    title: "낙타 표기법",
    description: `
      낙타 표기법(Camel Case)은 프로그래밍에서 변수, 함수 등 이름을 지을 때 사용하는 방법 중 하나예요. 여러 단어를 하나로 연결할 때, 첫 단어는 소문자로 시작하고, 다음 단어의 첫 글자를 대문자로 써서 읽기 쉽게 만드는 방식이에요. 예를 들어, myVariableName, calculateTotal 같은 이름들이 낙타 표기법이에요.
      
      1. 낙타 표기법이란?
         - 낙타 표기법(Camel Case): 첫 단어는 소문자로 시작하고, 이어지는 단어의 첫 글자를 대문자로 써요. 예를 들어, myName, totalAmount 등이 있어요.
      
      2. 왜 낙타 표기법을 사용할까요?
         - 읽기 쉬움: 이름이 길어질 때, 단어가 어디서 시작하고 끝나는지 쉽게 알 수 있어요.
         - 일관성: 팀에서 모두 같은 방식으로 이름을 짓기 때문에, 코드를 읽고 이해하기 쉬워져요.
         - 표준 준수: 많은 프로그래밍 언어와 도구들이 낙타 표기법을 권장하고 있어요.
      
      3. 낙타 표기법과 스네이크 표기법 비교하기:
         - 낙타 표기법(Camel Case): myVariableName
         - 스네이크 표기법(Snake Case): my_variable_name`,
    subject: "Computer Science",
    topic: "Programming Fundamentals",
    subTopic: "Naming Conventions",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Naming Conventions", "Camel Case", "Python"],
    examples: [
      {
        description: "낙타 표기법을 사용한 클래스와 함수 이름 (파이썬 예시)",
        code: `
  # 클래스 이름은 낙타 표기법을 사용해요.
  class DataProcessor:
      def __init__(self, data):
          self.data = data
      
      def processData(self):
          # 데이터를 처리하는 코드
          print('데이터를 처리하고 있어요.')
  
  # 함수 이름은 보통 스네이크 표기법을 사용해요.
  def calculate_total(amount, tax):
      total = amount + (amount * tax)
      return total
  
  # 클래스 사용 예시
  processor = DataProcessor([1, 2, 3])
  processor.processData()
  
  # 함수 사용 예시
  total = calculate_total(100, 0.05)
  print(f'총액: {total}')
      `
      }
    ],
    relatedConcepts: ["변수", "데이터 타입", "스네이크 표기법", "코드 읽기"],
    author: "SlopeXcelerity",
    dateCreated: "2024-04-27",
    lastUpdated: "2024-04-27",
    versionNumber: 1.0
  },

  // data-types-variables_010 // Data Types // code - 기본 데이터 타입 변수 선언 및 출력
  {
    problemId: "data-types-variables_010",
    image: null,
    title: "기본 데이터 타입 변수 선언 및 출력",
    description: `a, b, c, d 변수를 선언하여 각각 다른 데이터 타입으로 초기화하고 출력하세요.
    
    - 변수 a를 정수형으로 선언하고 정수 42로 초기화하세요.
    - 변수 b를 실수형으로 선언하고 실수 3.14로 초기화하세요.
    - 변수 c를 문자열형으로 선언하고 문자열 "Hello"로 초기화하세요.
    - 변수 d를 불리언형으로 선언하고 불리언 True로 초기화하세요.
    
    각각의 변수를 출력하세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Variables",
    subTopic: "Data Types",
    difficulty: 1,
    difficultyDescription: "기본 데이터 타입을 사용하여 변수를 선언하고 출력하는 방법을 배우는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Variables",
      "Data Types"
    ],
    programmingConcepts: ["변수", "데이터 타입", "출력"],
    skillsPracticed: ["변수 선언", "값 출력"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "기본 데이터 타입의 개념 이해하기",
      "다양한 데이터 타입을 사용하여 변수를 선언하고 출력하는 방법 배우기"
    ],
    commonMistakes: [
      "데이터 타입을 잘못 초기화하는 경우",
      "변수명을 일관성 없이 사용하는 경우"
    ],
    hints: {
      beginner: [
        "변수는 값을 저장할 수 있는 이름입니다. 변수에 값을 저장하려면 '=' 연산자를 사용하세요.",
        "print() 함수를 사용하여 변수의 값을 출력할 수 있습니다."
      ],
      intermediate: [
        "다양한 데이터 타입을 사용하여 변수를 선언해 보세요."
      ],
      advanced: [
        "복합적인 데이터 타입과 변수를 결합하여 더 복잡한 데이터를 관리할 수 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있습니다."]
      }
    },
    initialCode: "# 변수 a, b, c, d를 선언하고 각각의 데이터 타입으로 초기화해보세요.\n\n\n# 변수 a, b, c, d의 값을 출력하는 코드를 작성하세요.\nprint(a, b, c, d)",
    testCases: [
      {
        input: "",
        expected: `42 3.14 Hello True`
      }
    ],
    solution: "def initialize_variables():\n    a = 42\n    b = 3.14\n    c = \"Hello\"\n    d = True\n    print(a)\n    print(b)\n    print(c)\n    print(d)\n\ninitialize_variables()",
    examples: [
      {
        input: "",
        output: `42 3.14 Hello True`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.15
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  // data-types-variables_011 // Data Types // multiple_choice - 데이터 형 정확하게 구분하기
  {
    problemId: "data-types-variables_011",
    image: null,
    title: "데이터 형 정확하게 구분하기",
    description: `a = '42'라고 작성된 코드에서 변수 a에 저장된 값의 데이터 형은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "String",
    difficulty: 1,
    difficultyDescription: "기본적인 데이터 타입의 이해를 묻는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍 언어의 기본 개념",
      "Python의 기본 문법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Variables"
    ],
    programmingConcepts: ["데이터 타입"],
    skillsPracticed: ["데이터 타입 구분"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 문자열 데이터 타입 이해",
      "문자열과 다른 데이터 타입의 차이점 학습"
    ],
    commonMistakes: [
      "문자열과 숫자를 혼동함"
    ],
    hints: {
      beginner: [
        "문자열은 따옴표로 감싸져 있습니다.",
        "정수는 따옴표 없이 숫자만 사용합니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 따옴표로 감싸서 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "정수 (int)" },
      { id: "B", text: "실수 (float)" },
      { id: "C", text: "문자열 (str)" },
      { id: "D", text: "불리언 (bool)" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. Python에서 문자열은 따옴표로 감싸져 있는 데이터를 의미합니다. \"42\"는 숫자처럼 보이지만, 따옴표로 감싸져 있으므로 문자열입니다.",
    examples: [
      {
        input: `value = "42"`,
        output: `"42"`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  // data-types-variables_012 // Data Types // multiple_choice - 변수 a의 데이터형 변화
  {
    problemId: "data-types-variables_012",
    image: data_basics_010,
    title: "변수 a의 데이터형 변화",
    description: "위의 코드를 실행하면, 두 번째와 네 번째로 출력되는 변수 a의 데이터형은 각각 무엇일까요?",
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Types",
    subTopic: "Type Casting",
    difficulty: 2,
    difficultyDescription: "변수의 데이터형 변화를 이해하는 초급에서 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "변수의 개념",
      "데이터 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Variables",
      "Type Casting"
    ],
    programmingConcepts: ["데이터 타입 변경", "변수", "출력"],
    skillsPracticed: ["데이터 타입 구분", "변수의 값 변경"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 변수의 데이터형 변화를 이해한다.",
      "정수와 문자열 데이터형의 차이점을 학습한다."
    ],
    commonMistakes: [
      "데이터형 변경을 이해하지 못함",
      "정수와 문자열을 구분하지 못함"
    ],
    hints: {
      beginner: [
        "Python에서 변수는 언제든지 다른 데이터형의 값을 가질 수 있습니다.",
        "문자열은 따옴표로 감싸져 있습니다."
      ]
    },
    relatedConcepts: ["변수", "데이터 타입", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 변수는 타입을 명시하지 않고 선언할 수 있으며, 데이터형이 자유롭게 변경될 수 있습니다."]
      }
    },
    choices: [
      { id: "A", text: "정수 (int), 정수 (int)" },
      { id: "B", text: "정수 (int), 문자열 (str)" },
      { id: "C", text: "문자열 (str), 문자열 (str)" },
      { id: "D", text: "문자열 (str), 정수 (int)" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 첫 번째로 출력되는 a는 정수형(int)입니다. 이후에 a는 문자열 '42'로 재할당되어 문자열(str) 데이터형이 됩니다.",
    examples: [
      {
        input: "a = 42\nprint(a)\na = '42'\nprint(a)",
        output: "42\n'42'"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },

  //id: "built-in-functions",
  //title: "기본 기능 사용해보기",
  //topics: ["print", "input", "type"]
  ////////////////////////////////

  // python-programming-fundamentals_001 // functions // concept - 파이썬의 기본 기능들
  {
    problemId: "python-programming-fundamentals_001",
    type: "concept",
    title: "파이썬의 기본 기능들",
    image: null,
    description: `
      파이썬을 설치하면 다양한 기능들을 바로 사용할 수 있습니다.
      아주 기본적으로 데이터를 출력하거나, 입력받거나, 데이터를 조작하는 데 사용됩니다.

      프로그래밍에서 특정 기능을 가진 코드를 함수(function)라고 합니다.

      함수의 종류는 엄청나게 많습니다.
      취업을 목표로 하는게 아니라면 외우는걸 추천드리진 않습니다.
    `,
    subject: "Computer Science",
    topic: "functions",
    subTopic: "Basic Features",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Basic Features", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Functions", "Modules"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_002 // print // multiple_choice - print() 함수
  {
    problemId: "python-programming-fundamentals_002",
    title: "print() 함수",
    description: `데이터를 화면에 출력하는 함수는 print()입니다.
  문자열을 출력하려면 괄호 안에 따옴표로 감싸서 입력합니다.
  이때 작은 따옴표, 큰 따옴표 모두 사용할 수 있습니다.
  
  다음 중 'Hello, World!'를 올바르게 출력하는 코드는 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "print",
    subTopic: "Print Function",
    difficulty: 1,
    difficultyDescription: "기본 문법을 소개하는 초급 수준의 질문입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "컴퓨터에 대한 기본 이해",
      "프로그래밍 개념의 기본 이해"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Print Function",
      "Syntax",
      "First Program",
      "Hello World",
      "Output"
    ],
    programmingConcepts: ["출력", "함수", "문자열", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "문자열 리터럴"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 콘솔에 텍스트를 출력하는 방법 학습",
      "Python print 문장의 기본 문법 이해",
      "간단한 Python 프로그램의 구조 인식"
    ],
    commonMistakes: [
      "print 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "print 키워드를 잘못 철자함",
      "콜론이나 들여쓰기를 잘못 사용함",
      "print 함수에서 대문자 사용"
    ],
    hints: {
      beginner: [
        "프로그래밍에서 대소문자 구분은 아주 중요합니다.",
        "Python에서는 `print()` 함수를 사용하여 화면에 텍스트를 출력합니다.",
        "출력할 텍스트는 괄호 안에 따옴표로 감싸서 입력합니다.",
        "따옴표는 작은따옴표(')나 큰따옴표(\") 모두 사용할 수 있습니다."
      ],
      intermediate: [
        "`print()` 함수는 여러 값을 콤마로 구분하여 출력할 수 있습니다.",
        "`print()` 함수의 다양한 옵션을 활용하여 출력 형식을 조정해보세요.",
        ".format() 함수나 f-strings를 사용하여 다양한 출력 형식을 지정할 수 있습니다."
      ]
    },
    relatedConcepts: ["함수", "문자열", "문법", "표준 출력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서는 `print`가 함수이므로 괄호를 사용합니다."]
      }
    },
    choices: [
      { id: "A", text: "print 'Hello, World!'" },
      { id: "B", text: "print(Hello, World!)" },
      { id: "C", text: "PRINT('Hello, World!')" },
      { id: "D", text: "print('Hello, World!')" },
      { id: "E", text: "console.log('Hello, World!')" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. Python 3에서 'Hello, World!'를 출력하려면 `print 'Hello, World!'`와 같이 작성합니다. 이 방법은 Python 2에서 유효하지만, Python 3에서는 `print()` 함수를 사용해야 합니다. 따라서 실제로는 A번이 올바른 정답입니다.",
    examples: [
      {
        input: "print('Hello, World!')",
        output: "Hello, World!"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-06",
    lastUpdated: "2024-10-06",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_003 // print // code - Hello World!!! 출력하기
  {
    problemId: "python-programming-fundamentals_003",
    title: "Hello, World!!! 출력하기",
    description: `파이썬을 사용하여 'Hello, World!!!'를 출력하는 코드를 작성하세요.
    
                  [출력 예시]
                  Hello, World!!!`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "print",
    subTopic: "Print Function",
    difficulty: 1,
    difficultyDescription: "기본적인 출력 기능을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 출력 방법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Print Function",
      "Syntax",
      "First Program",
      "Hello World",
      "Output"
    ],
    programmingConcepts: ["출력", "함수", "문자열", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "문자열 리터럴"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 콘솔에 텍스트를 출력하는 방법 학습",
      "Python print 문장의 기본 문법 이해",
      "간단한 Python 프로그램의 구조 인식"
    ],
    commonMistakes: [
      "print 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "print 키워드를 잘못 철자함",
      "print 함수에서 대문자 사용"
    ],
    hints: {
      beginner: [
        "화면에 출력하는 함수는 print()입니다.",
        "프로그래밍에서 대소문자 구분은 아주 중요합니다.",
        "모든 문자, 심지어 공백까지 출력 예시와 똑같아야 합니다.",
        "출력할 텍스트는 괄호 안에 따옴표로 감싸서 입력합니다.",
      ]
    },
    relatedConcepts: ["함수", "문자열", "문법", "표준 출력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
      }
    },
    initialCode: `# 'Hello, World!!!'를 출력하는 코드를 작성하세요.
`,
    testCases: [
      { input: "", expected: "Hello, World!!!" }
    ],
    solution: `print("Hello, World!!!")`,
    examples: [
      {
        input: "",
        output: "Hello, World!!!"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_004 // input // multiple_choice - input() 함수
  {
    problemId: "python-programming-fundamentals_004",
    image: programming_fundamentals_003,
    title: "input() 함수와 데이터 타입",
    description: `위의 코드는 변수 a에 값을 입력받는 코드입니다.
  입력된 값은 문자열 데이터 타입으로 저장됩니다.

  다음 중 변수 a에 저장된 값의 데이터형은 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function",
    difficulty: 1,
    difficultyDescription: "기본적인 입력 함수와 데이터 타입을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Remember",
    cognitiveProcess: ["기억", "이해"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
      "input 키워드를 잘못 철자함"
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 사용하려면 변환이 필요합니다."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "정수형(int)" },
      { id: "B", text: "문자열(str)" },
      { id: "C", text: "실수형(float)" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. Python에서 input() 함수는 항상 문자열(string) 형태로 입력값을 반환합니다. 만약 다른 데이터 타입으로 사용하려면 int(), float() 등의 함수를 사용하여 변환해야 합니다.",
    examples: [
      {
        input: "사용자 입력 없음",
        output: "str"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_005 // input // code - 숫자 입력 받아 출력하기
  {
    problemId: "python-programming-fundamentals_005",
    title: "숫자 입력 받아 출력하기",
    description: `사용자가 텍스트를 입력하여 변수 n에 저장하고, 그 값을 출력하는 코드를 작성해보세요.
    
    [입력 예시]
    7
    
    [출력 예시]
    7
    
    **여기서는 직접 값을 입력하지 않아도 됩니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function",
    difficulty: 1,
    difficultyDescription: "기본적인 입력 기능을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Syntax",
      "User Input",
      "Number",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "숫자", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "숫자 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 숫자를 입력받는 방법 학습",
      "Python input 함수의 기본 문법 이해",
      "입력받은 숫자를 출력하는 방법 익히기"
    ],
    commonMistakes: [
      "input 함수에서 괄호를 생략함",
      "잘못된 따옴표 사용",
      "input 함수의 반환 값을 정수로 변환하지 않음 (문제에서는 문자열로 출력하므로)",
      "출력 형식에서 변수 사용 실수"
    ],
    hints: {
      beginner: [
        "사용자의 입력을 받기 위해서는 input() 함수를 사용합니다.",
        "입력받은 숫자를 변수에 저장한 후 출력에 사용하세요.",
        "입력받은 데이터는 기본적으로 문자열입니다."
      ]
    },
    relatedConcepts: ["함수", "숫자", "문법", "표준 입력", "주석"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `#변수 n을 선언하고, 텍스트를 입력받는 코드를 작성하세요.
n = 

# 입력받은 값을 출력합니다.
print()
`,
    testCases: [
      { input: "7", expected: "7" }
    ],
    solution: `n = input()
    print(n)`,
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_006 // functions // concept - 파이썬 내장 함수들
  {
    problemId: "python-programming-fundamentals_006",
    type: "concept",
    title: "파이썬 내장 함수들",
    image: programming_fundamentals_006,
    description: `
      print(), input() 처럼 파이썬을 설치만해도 사용할 수 있는 기능을
      내장 함수(function)라고 부릅니다.

      기본적으로 사용할 수 있는 기능도 많지만,
      추가적으로 사용할 수 있는 기능은 훨씬 더 많습니다.

      프로그래밍에서 단순히 기능을 외우는건 중요한게 아닙니다.
      어떤 프로그램을 만들지 그 목표를 분명하게 정하여,
      논리적이고 순서대로 과정을 만들어야합니다.

      끈기를 가지고 끝까지 문제를 해결해나가는것이 중요합니다.

      그러니 외우지말고, 필요할 때 생성형 AI와 구글링으로 정보를 찾아보는게 좋습니다.
      "파이썬 내장함수"라고 검색하여 공식 문서(https://docs.python.org/3/library/functions.html)를 확인하는 것이 정석입니다.
      
      **이미지는 실제 공식 문서의 3.13.0 버전의 내장함수 캡처화면입니다.
    `,
    subject: "Computer Science",
    topic: "functions",
    subTopic: "Built-in Functions",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Built-in Functions", "Fundamentals"],
    relatedConcepts: ["Variables", "Data Types", "Functions", "Modules"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_007 // input // code - 여러 데이터의 타입 확인하기
  {
    problemId: "python-programming-fundamentals_007",
    title: "여러 데이터의 타입 확인하기",
    description: `데이터의 타입을 확인하기 위해서 type() 함수를 사용할 수 있습니다.
    
    아래의 데이터를 저장하고 type() 함수를 사용하여 출력한 결과를 확인해보세요.

    - 문자열 type() 출력 ➡️ <class 'str'>
    - 정수 type() 출력  ➡️ <class 'int'>
    - 실수 type() 출력 ➡️ <class 'float'>
    - 불리언 type() 출력 ➡️ <class 'bool'>
    
    전체 코드를 꼼꼼하게 읽어보고, 🚩 Run 버튼을 클릭하여 결과를 확인해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Type Function",
    difficulty: 1,
    difficultyDescription: "여러 데이터 타입을 확인하고 type() 함수를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법",
      "데이터 타입 이해"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Function",
      "Syntax",
      "User Input",
      "Data Type",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 확인"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 다양한 데이터 타입을 입력받는 방법 학습",
      "type() 함수의 사용법 이해",
      "입력받은 데이터의 타입을 확인하는 방법 익히기"
    ],
    commonMistakes: [
      "type() 함수의 사용법을 잘못 이해함",
      "입력된 값을 제대로 출력하지 못함",
      "데이터 타입을 혼동함"
    ],
    hints: {
      beginner: [
        "`input()` 함수는 사용자의 입력을 문자열로 반환합니다.",
        "`type()` 함수를 사용하여 변수의 데이터 타입을 확인할 수 있습니다.",
        "각 데이터 타입에 맞는 값을 변수에 할당하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "정수", "실수", "불린", "데이터 타입", "변수", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `# 문자열을 입력받아 변수 s에 저장합니다.
s = "Hello"
# 정수를 입력받아 변수 i에 저장합니다.
i = 10
# 실수를 입력받아 변수 f에 저장합니다.
f = 3.14
# 불리언 값을 입력받아 변수 b에 저장합니다.
b = True

# 각 변수의 데이터 타입을 출력합니다.
print(type(s))
print(type(i))
print(type(f))
print(type(b))`,
    testCases: [
      {
        input: "",
        expected: `<class 'str'>
<class 'int'>
<class 'float'>
<class 'bool'>`
      }
    ],
    solution: `s = "Hello"
  i = 10
  f = 3.14
  b = True

  print(type(s))
  print(type(i))
  print(type(f))
  print(type(b))
  `,
    examples: [
      {
        input: "",
        output: "<class 'str'>\n<class 'int'>\n<class 'float'>\n<class 'bool'>"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_008 // input // multiple_choice - 문자를 정수로 변환하기
  {
    problemId: "python-programming-fundamentals_008",
    image: programming_fundamentals_008,
    title: "문자를 정수로 변환하기",
    description: `input() 함수로 입력받은 값은 모두 문자 데이터로 저장됩니다.
    위 코드처럼 문자와 숫자를 더하면 에러가 발생합니다.
    파이썬에서 문자와 숫자는 더할 수 없습니다.

    그래서 문자를 숫자로 변환해야합니다.
    이때 사용하는 함수가 int()입니다.
    
    세번째 줄에서 정수로 변환하는 코드를 입력해줘야 합니다.
    아래 보기에서 정수로 변환하는 방법으로 올바른 코드를 찾아보세요.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Input Function and Type Conversion",
    difficulty: 1,
    difficultyDescription: "input() 함수와 데이터 타입 변환을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법",
      "데이터 타입 변환"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Conversion",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "변환", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해", "타입 변환"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해",
      "input 받은 값을 int() 함수를 사용하여 정수로 변환하는 방법 학습"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 변환하기 위해 int() 함수를 사용하세요.",
        "변환된 값을 변수에 다시 저장한 후 출력에 사용하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "a = float(a)" },
      { id: "B", text: "a = int(a)" },
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. float() 함수는 실수로 변환하는 함수입니다.",
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_009 // int // code - int() 함수 사용해보기
  {
    problemId: "python-programming-fundamentals_009",
    image: programming_fundamentals_009,
    title: "int() 함수 사용해보기",
    description: `변수 n의 값을 정수로 변환해보세요.
    그냥 실행시켜보면, 위와 같은 오류가 발생합니다.
    오직 문자와 문자만 연결할 수 있다는 typeError입니다.
    
    이전 문제에서 배웠던 코드를 참고해서 직접 코드를 작성해보세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "input",
    subTopic: "Type Function",
    difficulty: 1,
    difficultyDescription: "여러 데이터 타입을 확인하고 type() 함수를 사용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 및 출력 방법",
      "데이터 타입 이해"
    ],
    estimatedTime: "3분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Function",
      "Syntax",
      "User Input",
      "Data Type",
      "Output"
    ],
    programmingConcepts: ["입력", "출력", "함수", "데이터 타입", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 확인"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 다양한 데이터 타입을 입력받는 방법 학습",
      "type() 함수의 사용법 이해",
      "입력받은 데이터의 타입을 확인하는 방법 익히기"
    ],
    commonMistakes: [
      "type() 함수의 사용법을 잘못 이해함",
      "입력된 값을 제대로 출력하지 못함",
      "데이터 타입을 혼동함"
    ],
    hints: {
      beginner: [
        "`input()` 함수는 사용자의 입력을 문자열로 반환합니다.",
        "`type()` 함수를 사용하여 변수의 데이터 타입을 확인할 수 있습니다.",
        "각 데이터 타입에 맞는 값을 변수에 할당하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "정수", "실수", "불린", "데이터 타입", "변수", "출력"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의하지 않고 내장 함수로 사용합니다."]
      }
    },
    initialCode: `# 변수 n에 사용자가 입력한 값을 저장합니다.
n = input()
# 변수 n에 저장된 문자를 정수로 변환하는 코드를 입력해보세요.

# 정수로 변환된 n과 정수 10을 더한 값을 출력합니다.
print(n+10)`,
    testCases: [
      {
        input: "7",
        expected: `17`
      }
    ],
    solution: `n = input()
    n = int(n)

    print(n+10)
  `,
    examples: [
      {
        input: "",
        output: "<class 'str'>\n<class 'int'>\n<class 'float'>\n<class 'bool'>"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  // python-programming-fundamentals_010 // input // multiple_choice - 이제 시작입니다
  {
    problemId: "python-programming-fundamentals_010",
    image: programming_fundamentals_008,
    title: "이제 시작입니다",
    description: `파이썬에서 아주 기본적으로 사용되는 함수에 대해서 알아봤습니다.
    이정도만 알아도 아주 기본적인 계산기 프로그램을 만들수 있죠.
    
    당장 개발자 취업을 위한게 아니라면,
    지금 당장은 코드를 외우거나 함수를 외우는건 프로그래밍을 공부하는 올바른 방법이 아닙니다.

    올바른 프로그래밍 공부 방법은 무엇일까요?
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "functions",
    subTopic: "Functios",
    difficulty: 1,
    difficultyDescription: "프로그래밍 공부 방법에 대해 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 입력 방법",
      "데이터 타입 변환"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Input Function",
      "Type Conversion",
      "Syntax",
      "First Program",
      "Input",
      "Data Type"
    ],
    programmingConcepts: ["입력", "함수", "데이터 타입", "변환", "문법"],
    skillsPracticed: ["간단한 코드 작성", "내장 함수 사용", "데이터 타입 이해", "타입 변환"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "Python에서 사용자로부터 입력을 받는 방법 학습",
      "input() 함수의 반환값 데이터 타입 이해",
      "input 받은 값을 int() 함수를 사용하여 정수로 변환하는 방법 학습"
    ],
    commonMistakes: [
      "input() 함수의 반환값을 정수형으로 오해",
      "input() 함수에서 괄호를 생략함",
    ],
    hints: {
      beginner: [
        "input() 함수는 사용자의 입력을 문자열로 반환합니다.",
        "입력받은 값을 정수형으로 변환하기 위해 int() 함수를 사용하세요.",
        "변환된 값을 변수에 다시 저장한 후 출력에 사용하세요."
      ]
    },
    relatedConcepts: ["함수", "문자열", "데이터 타입", "입력", "변환"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "multiple_choice",
    questionType: "coding",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["Python 3에서 input() 함수는 문자열을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "반드시 코드를 전부 다 외워서 사용해야한다." },
      { id: "B", text: "필요한 기능을 정확하게 파악하여 구현하기 위한 방법을 생성형 AI, 구글링 등으로 찾아본다." },
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 프로그래밍 함수는 너무 많기 때문에 전부다 외우는건 비효율적입니다.",
    examples: [
      {
        input: "7",
        output: "7"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.3
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-11",
    lastUpdated: "2024-10-11",
    versionNumber: 1.0
  },

  //id: "data-storage-and-access",
  //title: "데이터는 어떻게 컴퓨터에 저장될까?",
  //topics: ["memory_management", "Concept of Index", "Slicing"]
  ////////////////////////////////

  // basic-data-structure_001 // memory_management // concept - 메모리 내 데이터 저장 방법
  {
    problemId: "basic-data-structure_001",
    type: "concept",
    title: "메모리 내 데이터 저장 방법",
    image: basic_data_001,
    description: `
      데이터는 컴퓨터의 메모리에 저장됩니다.
      그리고 메모리는 이미지처럼 시각화 할 수 있습니다.
      1칸은 1bit의 공간을 차지합니다.

      우리는 8bit의 공간에 이진수 8개를 저장합니다.
      예를 들어, 01000001을 저장하면 문자 'A'를 저장하는 것입니다.
      즉, 'A'라는 문자 하나를 데이터에 저장하려면 8bit의 공간이 필요합니다.
      
      8bit는 1byte입니다.
      우리는 1byte를 기준으로 메모리에 어떤 값이 저장되는지 떠올리면서 공부해봅시다.
    `,
    subject: "Computer Science",
    topic: "memory_management",
    subTopic: "Data Storage",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Memory", "Data Storage"],
    relatedConcepts: ["Variables", "Data Types", "Memory Allocation"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // basic-data-structure_002 // data_types // multiple_choice - 'H' 문자 인덱싱 방법
  {
    problemId: "basic-data-structure_002",
    type: "multiple_choice",
    title: "문자는 하나씩 꺼내올 수도 있다",
    image: basic_data_002,
    description: `
      변수 word를 그대로 사용하는 방법말고도,
      변수 word에 저장된 문자를 하나씩 꺼내올 수 있습니다.
      
      마치 101호, 102호... 처럼 한 층에 데이터가 저장되어 있다고 생각하면 됩니다.
      이미지처럼 위치값이 생깁니다. 101호에 들어가있는 값을 꺼내오는 방법입니다.
      이런 방법을 인덱싱(Indexing)이라고 합니다.
      
      프로그래밍 세상에서 인덱싱은 예외없이 무조건 0부터 시작합니다.
      대괄호 안에 word[0]을 입력하면 문자열 "Hello"에서 'H'를 인덱싱할 수 있습니다.
      그렇다면 문자열 "Hello"에서 'e'를 인덱싱하려면 어떻게 해야할까요?
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`s[0]`을 사용하여 첫 번째 문자를 가져올 수 있습니다.",
        "중괄호 안에 정수를 사용하여 인덱싱을 합니다."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        indentationRequired: false,
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    choices: [
      { id: "A", text: "word['0']" },
      { id: "B", text: "word[0]" },
      { id: "C", text: "word[-1]" },
      { id: "D", text: "word[1]" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. 문자열의 첫 번째 문자는 인덱스 0을 사용하여 접근합니다. 따라서 `word[1]`은 'H'를 반환합니다.",
    examples: [
      {
        input: `
  word = "Hello"
  print(word[1])
        `,
        output: "e"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-12",
    lastUpdated: "2024-10-12",
    versionNumber: 1.0
  },

  // basic-data-structure_003 // data_types // code - 'o' 문자 인덱싱 확인하기
  {
    problemId: "basic-data-structure_003",
    title: "인덱싱 연습하기",
    description: `문자열 "Hello"에서 마지막 문자 'o'를 인덱싱하여 출력하는 코드를 작성하세요. `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열 인덱싱을 이해하고 적용하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용",
      "인덱스 번호를 잘못 입력함"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`word[4]`을 사용하여 다섯 번째 문자를 가져올 수 있습니다.",
        "문자열을 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: `# 문자열 "Hello"를 변수 word에 저장합니다.
word = "Hello"

# print() 함수를 사용하여 'o'를 출력하세요.
`,
    testCases: [
      { input: "", expected: "o" }
    ],
    solution: `word = "Hello"

  # 'o' 문자를 인덱싱하여 출력합니다.
  print(word[4])
  `,
    examples: [
      {
        input: "",
        output: "o"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_004 // data_types // code - 'Hello World!'에서 특정 문자 출력하기
  {
    problemId: "basic-data-structure_004",
    type: "code",
    title: "인덱싱 연습하기",
    description: `문자열 "Hello World!"에서 'H', 'W', '!' 문자를 인덱싱하여 출력하는 코드를 작성하세요.
    
  [출력 예시]
  H W !`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "String Indexing",
    difficulty: 1,
    difficultyDescription: "문자열에서 특정 문자를 인덱싱하여 출력하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 다루기",
      "인덱싱"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "String",
      "Indexing",
      "Variables"
    ],
    programmingConcepts: ["문자열", "인덱싱", "데이터 타입"],
    skillsPracticed: ["문자 추출", "인덱싱 사용"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열에서 인덱스를 사용하여 특정 문자를 추출하는 방법 이해하기",
      "인덱싱이 0부터 시작함을 학습하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 생각함",
      "잘못된 인덱스 사용",
      "인덱스 번호를 잘못 입력함"
    ],
    hints: {
      beginner: [
        "파이썬에서 문자열의 인덱스는 0부터 시작합니다.",
        "`word[0]`을 사용하여 첫 번째 문자를 가져올 수 있습니다.",
        "문자열을 출력하기 위해 `print()` 함수를 사용하세요."
      ]
    },
    relatedConcepts: ["문자열", "인덱스", "문자 추출"],
    learningStyle: ["실천적 학습", "시각적 학습"],
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    initialCode: `# 문자열 "Hello World!"를 변수 word에 저장합니다.
word = "Hello World!"

# 'H', 'W', '!'를 인덱싱하여 출력하는 코드를 작성하세요.
print(word[], word[], word[])`,
    testCases: [
      {
        input: "",
        expected: `H W !`
      }
    ],
    solution: `word = "Hello World!"

  print(word[0], word[6], word[11])`,
    examples: [
      {
        input: "",
        output: `H W !`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.25,
      guessingParameter: 0.2
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_005 // data_types // multiple_choice - 인덱스 개념 이해하기
  {
    problemId: "basic-data-structure_005",
    type: "multiple_choice",
    title: "인덱스 개념 이해하기",
    description: `인덱스 개념을 정확하게 이해하는 것은 프로그래밍에서 매우 중요합니다. 원하는 위치에 있는 데이터를 가져올 수 있어야 하며, 갈수록 인덱스 번호를 사용할 일이 많아집니다.

  다음 중 인덱스에 대한 설명으로 잘못된 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "Index Understanding",
    difficulty: 1,
    difficultyDescription: "인덱스의 기본 개념을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "프로그래밍의 기본 개념",
      "데이터 구조의 기초"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "Data Structures",
      "Indexing",
      "Concepts"
    ],
    programmingConcepts: ["인덱싱", "데이터 구조"],
    skillsPracticed: ["개념 이해", "오개념 식별"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "인덱스의 기본 개념 이해하기",
      "프로그래밍에서 인덱스의 중요성 인식하기"
    ],
    commonMistakes: [
      "인덱스가 1부터 시작한다고 오해함",
      "인덱스의 중요성을 과소평가함"
    ],
    hints: {
      beginner: [
        "대부분의 프로그래밍 언어에서 인덱스는 특정한 시작점을 가집니다.",
        "인덱스는 데이터의 위치를 나타내는 중요한 개념입니다."
      ]
    },
    relatedConcepts: ["배열", "리스트", "문자열 처리"],
    learningStyle: ["개념적 학습", "비판적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 인덱싱은 0부터 시작합니다."]
      }
    },
    choices: [
      { id: "A", text: "인덱스는 데이터의 위치를 나타낸다." },
      { id: "B", text: "인덱스를 사용하여 특정 위치의 데이터에 접근할 수 있다." },
      { id: "C", text: "위치값은 1부터 시작한다." }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. 대부분의 프로그래밍 언어에서 인덱스는 0부터 시작합니다. 1부터 시작한다는 것은 잘못된 설명입니다. A, B, D는 모두 인덱스에 대한 올바른 설명입니다.",
    examples: [],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_006 // data_types // concept - 파이썬의 슬라이싱 이해하기
  {
    problemId: "basic-data-structure_006",
    type: "concept",
    title: "슬라이싱 이해하기",
    image: basic_data_003,
    description: `
      슬라이싱(Slicing)은 범위를 지정하여 여러개의 요소를 출력하는 방법입니다.
      word[1:4:1]는 "ell"를 출력합니다.
      
      [start:end:step]
      
      - start: 슬라이싱을 시작할 인덱스 (default: 0)
      - end: 슬라이싱을 끝낼 인덱스 (default: 끝값)
      - step: 슬라이싱 간격 (default: 1)

      다양한 방법으로 슬라이싱 할 수 있습니다. 예를 들어,
      word[0:5:1]은 "hello"를 출력합니다.
      word[0:5:2]은 "hlo"를 출력합니다.
      word[0:5:-1]은 "olleh"를 출력합니다.

      그리고 줄여서 사용할 수도 있습니다. 예를 들어,
      시작값이 0이라면 word[:3:1]라고 사용해도 됩니다.
      증가값이 1이라면 word[2:4:]라고 사용해도 됩니다.
      처음부터 끝까지 출력하고 싶다면 word[:]라고 사용해도 됩니다.
      dafault는 생략 가능합니다.
      
      반복문에서 배우게 될 range()라는 함수도 슬라이싱 방법과 유사하게 사용됩니다.

      어디부터, 어디까지, 얼마나 증가하는지 값을 정하기 위해서는
      출력하고자 하는 데이터의 위치값을 정확하게 알고 있어야합니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 개념과 활용법을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "인덱싱 개념",
      "시퀀스 타입(문자열, 리스트 등)"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "시퀀스 타입"],
    skillsPracticed: ["데이터 추출", "문자열 조작"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱의 기본 문법 이해하기",
      "슬라이싱을 사용하여 시퀀스의 일부분을 추출하는 방법 학습",
      "음수 인덱스와 역순 슬라이싱의 개념 파악하기"
    ],
    commonMistakes: [
      "end 인덱스가 포함된다고 오해함",
      "음수 인덱스의 의미를 잘못 이해함",
      "step의 역할을 혼동함"
    ],
    relatedConcepts: ["인덱싱", "시퀀스 타입", "문자열 처리", "리스트 조작"],
    learningStyle: ["개념적 학습", "시각적 학습"],
    accessibilityFeatures: ["이미지에 대한 대체 텍스트 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_008 // data_types // multiple_choice - 올바른 슬라이싱 방법 선택하기
  {
    problemId: "basic-data-structure_008",
    image: basic_data_004,
    type: "multiple_choice",
    title: "올바른 슬라이싱 방법",
    description: `"o W"를 추출하기 위한 올바른 슬라이싱 방법을 고르세요.
    공백도 하나의 문자입니다.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 방법을 이해하고 적용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "분석"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 인덱싱",
      "슬라이싱 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "문자열 조작"],
    skillsPracticed: ["슬라이싱 적용", "문자열 추출"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱의 start, end, step 개념 적용하기",
      "정확한 문자열 부분 추출 방법 이해하기",
      "공백을 포함한 문자열 슬라이싱 이해하기"
    ],
    commonMistakes: [
      "start 인덱스를 잘못 계산함",
      "end 인덱스가 포함된다고 오해함",
      "step의 의미를 잘못 이해함",
      "공백을 문자로 취급하지 않음"
    ],
    hints: {
      beginner: [
        "슬라이싱의 start 인덱스는 포함됩니다.",
        "end 인덱스는 포함되지 않습니다.",
        "step은 건너뛰는 간격을 의미합니다.",
        "공백도 하나의 문자로 취급됩니다."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리", "시퀀스 타입"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 슬라이싱은 [start:end:step] 형식을 사용합니다."]
      }
    },
    choices: [
      { id: "A", text: "word[4:7:1]" },
      { id: "B", text: "word[4:7:2]" },
      { id: "C", text: "word[4~7~1]" },
      { id: "D", text: "word[4:8:2]" }
    ],
    correctAnswer: "A",
    solution: "정답은 A입니다. word[4:7:1]는 인덱스 4부터 시작하여 7 직전까지, 1칸씩 이동하며 슬라이싱합니다. 이는 'o'(인덱스 4), ' '(인덱스 5), 'W'(인덱스 6)를 선택하여 'o W'를 정확히 추출합니다. B는 'oW'를 추출합니다. C는 'lo '를 추출합니다. D는 'o'만 추출합니다.",
    examples: [
      {
        input: "word[4:7:1]",
        output: "o W"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_009 // data_types // code - 슬라이싱 연습하기
  {
    problemId: "basic-data-structure_009",
    type: "code",
    title: "슬라이싱 연습하기",
    description: `주어진 문자열 "Python Programming"에서 다음 조건에 맞는 부분 문자열을 출력하는 코드를 작성하세요. 문자열이 어떻게 저장되어 있을지 머리속으로 시각화해보세요.

  1. "thon"을 출력하세요.
  2. "Program"을 출력하세요.

  각 결과를 print() 함수를 사용하여 출력하세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Data Structures",
    subTopic: "Slicing",
    difficulty: 2,
    difficultyDescription: "슬라이싱의 기본 방법을 적용하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "분석"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열 인덱싱",
      "슬라이싱 기본 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Data Structures",
      "Slicing",
      "String Manipulation"
    ],
    programmingConcepts: ["슬라이싱", "인덱싱", "문자열 조작"],
    skillsPracticed: ["슬라이싱 적용", "문자열 추출"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "슬라이싱을 사용하여 특정 부분 문자열 추출하기",
      "start와 end 인덱스를 정확히 계산하여 적용하기"
    ],
    commonMistakes: [
      "start와 end 인덱스 계산 오류",
      "슬라이싱 범위 설정 실수"
    ],
    hints: {
      beginner: [
        "첫 번째 문제는 'Python'에서 'thon'을 추출해야 합니다.",
        "두 번째 문제는 'Programming'에서 'Program'을 추출해야 합니다.",
        "인덱스는 0부터 시작한다는 것을 기억하세요."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리", "시퀀스 타입"],
    learningStyle: ["실습 학습", "문제 해결 학습"],
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 슬라이싱은 [start:end] 형식을 사용합니다."]
      }
    },
    initialCode: `word = "Python Programming"

# 1. "thon" 출력
print()

# 2. "Program" 출력
print()`,
    testCases: [
      {
        input: "",
        expected: `thon
Program`
      }
    ],
    solution: `word = "Python Programming"

  # 1. "thon"
  print(word[2:6])

  # 2. "Program"
  print(word[7:14])`,
    examples: [
      {
        input: "",
        output: `thon
  Program`
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_010 // data_types // concept - 위치값과 연관된 함수
  {
    problemId: "basic-data-structure_010",
    image: basic_data_005,
    type: "concept",
    title: "위치값과 연관된 함수",
    description: `len(), index() 함수는 문자열을 다룰 때 자주 사용되는 함수입니다.

    len() 함수는 문자열의 길이를 반환합니다.
    즉, 요소의 개수를 파악할 수 있습니다.

    index() 함수는 특정 문자의 위치값을 반환합니다.
    즉, 특정 문자의 위치를 찾을 수 있습니다.

    문자열 처리 작업을 하는데 있어 위치값을 정확하게 파악하고 사용하는 것이 매우 중요합니다. 특히 len() 함수는 슬라이싱, range() 함수 등에서도 자주 사용됩니다.
    `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "len() and index()",
    difficulty: 2,
    difficultyDescription: "len()과 index() 함수의 기본 개념을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열의 기본 개념",
      "인덱싱의 기본 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "String Functions",
      "len()",
      "index()"
    ],
    programmingConcepts: ["문자열 함수", "문자열 길이", "문자열 검색"],
    skillsPracticed: ["문자열 길이 계산", "문자열 내 위치 찾기"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "len() 함수의 사용법과 반환값 이해하기",
      "index() 메서드의 사용법과 반환값 이해하기",
      "문자열 처리에서 len()과 index()의 활용 방법 파악하기"
    ],
    commonMistakes: [
      "len() 함수의 결과와 인덱스를 혼동함",
      "index() 메서드가 찾지 못할 때 발생하는 예외를 처리하지 않음",
      "대소문자 구분을 고려하지 않고 index() 메서드를 사용함"
    ],
    relatedConcepts: ["문자열 슬라이싱", "문자열 메서드", "예외 처리"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_011 // data_types // multiple_choice - len() 함수 이해하기
  {
    problemId: "basic-data-structure_011",
    image: basic_data_006,
    type: "multiple_choice",
    title: "len() 함수 이해하기",
    description: `위 코드의 올바른 출력값을 고르세요. 공백과 특수문자도 문자라는 사실을 잊지마세요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Concept of Index",
    subTopic: "len()",
    difficulty: 1,
    difficultyDescription: "len() 함수의 기본적인 사용법을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["사실적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 문법",
      "문자열의 기본 개념"
    ],
    estimatedTime: "1분",
    tags: [
      "Python",
      "Beginner",
      "String Functions",
      "len()"
    ],
    programmingConcepts: ["문자열 함수", "문자열 길이"],
    skillsPracticed: ["문자열 길이 계산"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "len() 함수의 사용법 이해하기",
      "문자열 길이 계산 방법 학습하기"
    ],
    commonMistakes: [
      "공백을 문자로 세지 않음",
      "특수문자를 문자로 세지 않음",
      "len() 함수의 결과를 인덱스와 혼동함"
    ],
    hints: {
      beginner: [
        "len() 함수는 모든 문자를 세어 길이를 반환합니다.",
        "공백도 하나의 문자로 취급됩니다.",
        "특수문자(쉼표, 느낌표 등)도 문자로 세어집니다."
      ]
    },
    relatedConcepts: ["인덱싱", "문자열 처리"],
    learningStyle: ["개념적 학습", "시각적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 len() 함수는 문자열의 길이를 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "11" },
      { id: "B", text: "12" },
      { id: "C", text: "13" },
      { id: "D", text: "14" }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 'Hello World!'는 총 12개의 문자로 구성되어 있습니다. 여기에는 'H', 'e', 'l', 'l', 'o', ' ', 'W', 'o', 'r', 'l', 'd', '!'가 포함됩니다. len() 함수는 모든 문자(공백과 특수문자 포함)를 세기 때문에 결과값은 13입니다.",
    examples: [
      {
        input: "len('Hello, World!')",
        output: "12"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.0
  },

  // basic-data-structure_012 // data_types // multiple_choice - 정수형 데이터 특성 이해하기
  {
    problemId: "basic-data-structure_012",
    image: basic_data_007,
    type: "multiple_choice",
    title: "정수형 데이터 특성 이해하기",
    description: `정수 데이터는 4byte 메모리를 차지합니다.
    문자 하나는 1byte 공간을 차지하고, 정수 하나는 4btye 공간을 차지하죠.

    (32비트 or 64비트 운영체제에 따라 사용되는 메모리 크기가 다를 수 있습니다. 일단 우리는 32비트 운영체제를 사용한다고 가정하겠습니다.)

    문자열 데이터를 변수에 저장한다면 인덱싱/슬라이싱이 가능하지만,
    정수 데이터는 인덱싱/슬라이싱이 불가능합니다.
    이미지처럼 위치값이 없기 때문입니다.

    그렇다면 정수형 데이터에 대한 설명으로 올바른 것은 무엇일까요?
  `,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "memory_management",
    subTopic: "Integer",
    difficulty: 1,
    difficultyDescription: "정수형 데이터의 기본 특성을 이해하는 초급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "파이썬의 기본 데이터 타입",
      "인덱싱의 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Beginner",
      "Data Types",
      "Integer"
    ],
    programmingConcepts: ["데이터 타입", "정수", "인덱싱"],
    skillsPracticed: ["데이터 타입 특성 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "정수형 데이터의 기본 특성 이해하기",
      "정수형 데이터와 인덱싱의 관계 인식하기"
    ],
    commonMistakes: [
      "정수형 데이터를 인덱싱할 수 있다고 오해함",
      "정수형 데이터를 시퀀스 타입으로 잘못 이해함"
    ],
    hints: {
      beginner: [
        "정수는 단일 값을 나타내는 스칼라 타입입니다.",
        "인덱싱은 시퀀스 타입의 데이터에 적용되는 개념입니다.",
        "파이썬에서 모든 데이터 타입이 인덱싱을 지원하는 것은 아닙니다."
      ]
    },
    relatedConcepts: ["데이터 구조", "시퀀스 타입", "스칼라 타입"],
    learningStyle: ["개념적 학습"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["파이썬에서 정수는 int 클래스의 인스턴스입니다."]
      }
    },
    choices: [
      { id: "A", text: "정수형 데이터는 인덱싱이 가능하며, 각 자릿수에 접근할 수 있다." },
      { id: "B", text: "정수형 데이터는 인덱싱이 불가능하며, 단일 값으로 취급된다." },
      { id: "C", text: "정수형 데이터는 항상 양수만 표현할 수 있다." },
      { id: "D", text: "정수형 데이터는 소수점 이하 자리를 가질 수 있다." }
    ],
    correctAnswer: "B",
    solution: "정답은 B입니다. 파이썬에서 정수형 데이터는 단일 값을 나타내는 스칼라 타입이므로 인덱싱이 불가능합니다. 정수는 하나의 전체 값으로 취급되며, 개별 자릿수에 직접 접근할 수 없습니다. 인덱싱은 문자열이나 리스트와 같은 시퀀스 타입의 데이터에 적용되는 개념입니다.",
    examples: [
      {
        input: "5[0]",
        output: "TypeError: 'int' object is not subscriptable"
      },
      {
        input: "isinstance(42, int)",
        output: "True"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-13",
    lastUpdated: "2024-10-13",
    versionNumber: 1.2
  },

  //id: "operators",
  //title: "프로그래밍 연산자",
  //topics: ["Arithmetic Operators", "Comparison Operators", "Logical Operators", "Assignment Operators"]
  ////////////////////////////////
  
  // operators_001 // Arithmetic Operators // concept - 기본 산술 연산자
  {
    problemId: "operators_001",
    type: "concept",
    title: "기본 산술 연산자",
    image: operators_001,
    description: `프로그래밍에서 사용되는 산술 연산의 종류입니다.

    이러한 연산자들은 프로그래밍에서 수학적 계산을 수행하는 데 사용됩니다. 
    연산자의 우선순위는 일반적인 수학 규칙을 따르며, 괄호를 사용하여 연산 순서를 명시적으로 지정할 수 있습니다.
    
    프로그래밍에서는 문자와 정수, 문자와 문자 등 다양한 데이터 타입도 가능한 산술 연산이 존재합니다.`,
    subject: "Computer Science",
    topic: "Arithmetic Operators",
    subTopic: "Basic Arithmetic",
    difficulty: 1,
    tags: ["Programming", "Beginner", "Arithmetic", "Operators"],
    relatedConcepts: ["Variables", "Data Types", "Expressions"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_002 // Arithmetic Operators // multiple_choice - 불가능한 문자 연산
  {
    problemId: "operators_002",
    type: "multiple_choice",
    title: "불가능한 문자 연산",
    description: `다음 보기에서 연산이 불가능한 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Arithmetic Operators",
    subTopic: "String Operations",
    difficulty: 2,
    difficultyDescription: "문자열 연산의 특성을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 연산",
      "산술 연산자"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "String Operations",
      "Arithmetic Operators"
    ],
    programmingConcepts: ["문자열 연산", "산술 연산자", "타입 호환성"],
    skillsPracticed: ["연산자 이해", "문자열 처리"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열과 다른 데이터 타입 간의 연산 가능성 이해",
      "Python에서의 문자열 연산 규칙 파악"
    ],
    commonMistakes: [
      "모든 산술 연산자가 문자열에 적용 가능하다고 오해함",
      "문자열과 숫자의 연산 규칙을 혼동함"
    ],
    hints: {
      beginner: [
        "문자와 문자는 + 연산자로 연결가능합니다.",
        "문자와 정수는 * 연산자 사용가능합니다.",
        "문자열과 숫자는 서로 다른 데이터 타입입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "연산자 오버로딩"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 불변(immutable) 객체입니다."]
      }
    },
    choices: [
      { id: "A", text: "'Hello' + ' World'" },
      { id: "B", text: "'Python' * 3" },
      { id: "C", text: "'5' - 3" }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. 문자와 숫자는 뺄 수 없습니다.",
    examples: [
      {
        input: "'Hello' + ' World'",
        output: "'Hello World'"
      },
      {
        input: "'Python' * 3",
        output: "'PythonPythonPython'"
      },
      {
        input: "'5' - 3",
        output: "TypeError: unsupported operand type(s) for -: 'str' and 'int'"
      },
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_003 // Arithmetic Operators // multiple_choice - 가능한 문자 연산
  {
    problemId: "operators_003",
    type: "multiple_choice",
    title: "가능한 산술 연산 선택하기",
    description: `문자와 문자는 + 연산으로 연결(concatenation) 가능하고, 문자와 정수는 * 연산으로 곱셈이 가능합니다.
    
    데이터 타입에 따라 어떤 연산이 가능한지 미리 알고 있어야 합니다.
    프로그래밍에서 데이터 타입에 따라 불가능한 연산들이 존재하기 때문입니다.
    만약 이 규칙을 어긴다면, 프로그램이 오류를 발생시키거나 예상치 못한 결과를 출력할 수 있습니다.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Arithmetic Operators",
    subTopic: "String and Integer Operations",
    difficulty: 2,
    difficultyDescription: "문자열과 정수 간의 연산 특성을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "문자열 연산",
      "산술 연산자",
      "타입 변환"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "String Operations",
      "Arithmetic Operators",
      "Type Conversion"
    ],
    programmingConcepts: ["문자열 연산", "산술 연산자", "타입 호환성", "타입 변환"],
    skillsPracticed: ["연산자 이해", "문자열 처리", "타입 분석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "문자열과 정수 간의 연산 가능성 이해",
      "Python에서의 문자열과 정수 연산 규칙 파악",
      "암시적 및 명시적 타입 변환의 이해"
    ],
    commonMistakes: [
      "모든 산술 연산자가 문자열과 정수 사이에 적용 가능하다고 오해함",
      "문자열과 정수의 연산 규칙을 혼동함",
      "암시적 타입 변환이 항상 일어난다고 가정함"
    ],
    hints: {
      beginner: [
        "문자와 문자는 + 연산자로 연결가능합니다.",
        "문자와 정수는 * 연산자 사용가능합니다.",
        "문자열과 숫자는 서로 다른 데이터 타입입니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "연산자 오버로딩"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 문자열은 불변(immutable) 객체입니다."]
      }
    },
    choices: [
      { id: "A", text: "'5' - 3" },
      { id: "B", text: "'Python' / 2" },
      { id: "C", text: "'abc' * 'def'" },
      { id: "D", text: "int('5') + 3" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. int('5') + 3은 문자열 '5'를 정수로 변환한 후 3과 더하는 유효한 연산입니다. A는 문자열과 정수의 뺄셈, B는 문자열과 정수의 나눗셈, C는 문자열과 문자열의 곱셈으로 모두 Python에서 지원되지 않는 연산입니다.",
    examples: [
      {
        input: "'5' - 3",
        output: "TypeError: unsupported operand type(s) for -: 'str' and 'int'"
      },
      {
        input: "'Python' / 2",
        output: "TypeError: unsupported operand type(s) for /: 'str' and 'int'"
      },
      {
        input: "'abc' * 'def'",
        output: "TypeError: can't multiply sequence by non-int of type 'str'"
      },
      {
        input: "int('5') + 3",
        output: "8"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_004 // Arithmetic Operators // concept - 나누기 연산자의 종류
  {
    problemId: "operators_004",
    type: "concept",
    title: "이제 나누기를 연습해볼까요?",
    image: operators_002,
    description: `나누기 연산자에는 세 가지 종류가 있습니다.

    1. 일반 나누기 (/): 실수 타입 결과 반환(소수점 이하 16자리까지 표시)
    2. 몫 나누기 (//): 나눗셈 몫만 반환
    3. 나머지 연산 (%): 나눗셈 나머지를 반환

    이러한 나누기 연산자들은 프로그래밍에서 다양한 상황에 활용됩니다. 
    짝수/홀수 판별, 시간 계산, 데이터 분할 등에 사용될 수 있습니다.`,
    subject: "Computer Science",
    topic: "Arithmetic Operators",
    subTopic: "Division Operations",
    difficulty: 2,
    difficultyDescription: "나누기 연산자의 종류와 특성을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "구별"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 산술 연산",
      "정수와 실수의 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Arithmetic Operators",
      "Division"
    ],
    programmingConcepts: ["나누기 연산", "데이터 타입", "연산자 종류"],
    skillsPracticed: ["연산자 구별", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "세 가지 나누기 연산자의 차이점 이해",
      "각 연산자의 결과 타입 파악",
      "적절한 상황에 맞는 나누기 연산자 선택 능력 향상"
    ],
    commonMistakes: [
      "일반 나누기(/)와 몫 나누기(//)를 혼동함",
      "나머지 연산(%)의 결과를 잘못 해석함",
      "연산 결과의 데이터 타입을 고려하지 않음"
    ],
    relatedConcepts: ["데이터 타입", "타입 변환", "정수 나눗셈"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명", "예시 코드 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_005 // Comparison Operators // concept - 비교 연산자 이해하기
  {
    problemId: "operators_005",
    type: "concept",
    title: "두 값 비교하기",
    image: operators_003,
    description: `두 값을 비교할 수 있는 비교 연산자도 있습니다.
    비교한 결과는 불리언 타입(참, 거짓)으로 반환됩니다.
    참은 True(1), 거짓은 False(0)로 표현한다는 사실 잊지 않으셨죠?

    1. 크다 (>): 왼쪽 값이 오른쪽 값보다 크면 True, 아니면 False를 반환합니다.
    2. 작다 (<): 왼쪽 값이 오른쪽 값보다 작으면 True, 아니면 False를 반환합니다.
    3. 크거나 같다 (>=): 왼쪽 값이 오른쪽 값보다 크거나 같으면 True, 아니면 False를 반환합니다.
    4. 작거나 같다 (<=): 왼쪽 값이 오른쪽 값보다 작거나 같으면 True, 아니면 False를 반환합니다.
    5. 같음 (==): 두 값이 같으면 True, 다르면 False를 반환합니다.
    6. 다름 (!=): 두 값이 다르면 True, 같으면 False를 반환합니다.

    비교 연산자는 조건문(if, elif, else)이나 반복문(while)에서 자주 사용되며, 프로그램의 흐름을 제어하는 데 중요한 역할을 합니다.`,
    subject: "Computer Science",
    topic: "Comparison Operators",
    subTopic: "Basic Comparison",
    difficulty: 2,
    difficultyDescription: "비교 연산자의 종류와 특성을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "적용"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 산술 연산",
      "불리언(Boolean) 타입의 개념"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Intermediate",
      "Comparison Operators",
      "Boolean"
    ],
    programmingConcepts: ["비교 연산", "불리언 로직", "조건문"],
    skillsPracticed: ["연산자 구별", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "다양한 비교 연산자의 기능 이해",
      "비교 연산의 결과 타입 파악",
      "비교 연산자를 활용한 조건 표현 능력 향상"
    ],
    commonMistakes: [
      "같음(==)과 할당(=) 연산자를 혼동함",
      "비교 연산의 결과가 항상 불리언 타입임을 간과함",
      "연결된 비교 연산의 의미를 잘못 해석함"
    ],
    relatedConcepts: ["조건문", "반복문", "논리 연산자"],
    learningStyle: ["개념적 학습", "예시 기반 학습"],
    accessibilityFeatures: ["텍스트 기반 설명", "예시 코드 제공"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_006 // Comparison Operators // multiple_choice - False를 반환하는 비교 연산자
  {
    problemId: "operators_006",
    type: "multiple_choice",
    title: "False를 반환하는 경우는?",
    description: `문자와 문자, 문자와 정수에 대해서도 사용가능한 비교연산자가 있습니다.
    문자와 문자, 문자와 정수 데이터 타입은 각각의 크기를 비교하는건 불가능하겠지만, 같다/같지않다는 따져볼 수 있을겁니다.
    
    문자와 정수의 크기도 한 번 비교해보세요.
    '5' > 3이라고 비교한다면 TypeError: '>' not supported between instances of 'str' and 'int'라는 에러가 발생합니다.

    에러 메시지가 뜨는걸 두려워할 필요없습니다.
    다양한 코드를 입력해보면서 오류를 맞이해보세요.
    놀랍게도 프로그래밍은 많은 오류를 해결해본 사람이 훨씬 잘하게 될테니까요.`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Comparison Operators",
    subTopic: "Boolean Results",
    difficulty: 2,
    difficultyDescription: "비교 연산자의 결과를 예측하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["이해", "적용", "분석"],
    knowledgeDimension: ["개념적 지식", "절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "비교 연산자",
      "불리언 타입"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Comparison Operators",
      "Boolean"
    ],
    programmingConcepts: ["비교 연산", "불리언 로직"],
    skillsPracticed: ["연산자 이해", "결과 예측"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "비교 연산자의 결과 예측 능력 향상",
      "다양한 데이터 타입에 대한 비교 연산 이해"
    ],
    commonMistakes: [
      "문자열 비교 시 대소문자를 고려하지 않음",
      "다른 데이터 타입 간의 비교 결과를 잘못 예측함"
    ],
    hints: {
      beginner: [
        "비교 연산자는 두 값을 비교합니다.",
        "결과는 항상 True 또는 False입니다.",
        "문자열 비교 시 대소문자를 구분합니다."
      ]
    },
    relatedConcepts: ["데이터 타입", "타입 변환", "조건문"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 비교 연산자는 불리언 값을 반환합니다."]
      }
    },
    choices: [
      { id: "A", text: "'5' == '5'" },
      { id: "B", text: "3 <= 3" },
      { id: "C", text: "10 > 5" },
      { id: "D", text: "'python' == 'Python'" }
    ],
    correctAnswer: "D",
    solution: "정답은 D입니다. 'python' == 'Python'은 False를 반환합니다. Python에서 문자열 비교는 대소문자를 구분하기 때문입니다.",
    examples: [
      {
        input: "'5' == '5'",
        output: "True"
      },
      {
        input: "3 <= 3",
        output: "True"
      },
      {
        input: "10 > 5",
        output: "True"
      },
      {
        input: "'python' == 'Python'",
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_007 // Logical Operators // concept - 논리 연산자와 논리회로의 기초
  {
    problemId: "operators_007",
    type: "concept",
    title: "여러 조건을 다루는 논리 연산자",
    image: null,
    description: `지금까지는 하나의 경우에 대해서 비교했지만, 실제 프로그래밍에서는 여러 조건을 동시에 고려해야 하는 경우가 많습니다. 이때 사용되는 것이 논리 연산자입니다.
    이러한 논리 연산자들은 컴퓨터 내부의 논리회로를 기반으로 작동합니다. 논리회로는 디지털 시스템의 기본 구성 요소로, 0과 1의 이진 신호를 처리합니다.

    논리회로와 논리 연산자가 중요한 이유는 너무나도 많습니다. 예를 들면,
    1. 복잡한 의사결정: 여러 조건을 조합하여 복잡한 상황을 표현할 수 있습니다.
    2. 효율적인 코드: 여러 조건을 간결하게 표현하여 코드의 가독성과 효율성을 높입니다.
    3. 하드웨어와 소프트웨어의 연결: 논리회로는 하드웨어의 기본이며, 이를 이해하면 컴퓨터의 작동 원리를 더 깊이 이해할 수 있습니다.
    4. 알고리즘 설계: 복잡한 알고리즘을 설계할 때 논리 연산은 핵심적인 역할을 합니다.
    5. 데이터 처리: 대량의 데이터를 필터링하거나 분석할 때 논리 연산이 필수적입니다.

    프로그래밍에서 논리 연산자를 이해하고 활용하는 것은 효과적인 코드 작성의 기본이 됩니다.`,
    subject: "Computer Science",
    topic: "Logical Operators",
    subTopic: "Basic Logic Circuits",
    difficulty: 2,
    difficultyDescription: "논리 연산자와 논리회로의 기본 개념을 이해하는 중급 수준의 개념입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "기본적인 불리언 연산",
      "조건문의 기초"
    ],
    estimatedTime: "7분",
    tags: [
      "Python",
      "Intermediate",
      "Logical Operators",
      "Logic Circuits"
    ],
    programmingConcepts: ["논리 연산", "불리언 로직", "디지털 시스템"],
    skillsPracticed: ["논리적 사고", "복합 조건 분석"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "기본적인 논리 연산자(AND, OR, NOT)의 기능 이해",
      "논리회로의 기본 개념과 중요성 인식",
      "프로그래밍에서 논리 연산자의 활용 방법 파악"
    ],
    commonMistakes: [
      "AND와 OR 연산의 결과를 혼동함",
      "복합 조건에서 연산자 우선순위를 고려하지 않음",
      "논리 연산의 결과가 항상 불리언 타입임을 간과함"
    ],
    relatedConcepts: ["조건문", "불리언 대수", "디지털 로직"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    accessibilityFeatures: ["텍스트 기반 설명", "시각적 다이어그램"],
    technologyRequirements: ["없음"],
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.0
  },

  // operators_008 // Logical Operators // multiple_choice - AND 게이트 이해하기
  {
    problemId: "operators_008",
    image: operators_004,
    type: "multiple_choice",
    title: "AND 게이트 이해하기",
    description: `AND 게이트는 논리 회로의 기본 요소 중 하나입니다. AND 게이트의 특성은 다음과 같습니다:

    - 두 개 이상의 입력을 받습니다.
    - 모든 입력이 1(True)일 때만 출력이 1(True)입니다.
    - 하나라도 0(False)이면 출력은 0(False)입니다.

    다음 중 AND 게이트의 동작을 올바르게 설명한 것은 무엇일까요?`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "Logical Operators",
    subTopic: "AND Gate",
    difficulty: 2,
    difficultyDescription: "AND 게이트의 기본 개념을 이해하는 중급 수준의 문제입니다.",
    cognitiveLevel: "Understand",
    cognitiveProcess: ["이해", "분석"],
    knowledgeDimension: ["개념적 지식"],
    prerequisiteKnowledge: [
      "불리언 대수의 기초",
      "AND 연산의 기본 개념"
    ],
    estimatedTime: "2분",
    tags: [
      "Python",
      "Intermediate",
      "Logical Operators",
      "Boolean Algebra",
      "AND Gate"
    ],
    programmingConcepts: ["논리 연산", "불리언 로직", "AND 게이트"],
    skillsPracticed: ["논리적 사고", "연산자 이해"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "AND 게이트의 기본 동작 이해",
      "AND 연산의 결과 예측 능력 향상"
    ],
    commonMistakes: [
      "AND와 OR 연산의 결과를 혼동함",
      "불리언 값의 의미를 잘못 해석함"
    ],
    hints: {
      beginner: [
        "AND 연산은 모든 조건이 참일 때만 참입니다.",
        "하나라도 거짓이면 결과는 거짓입니다.",
        "False는 0, True는 1로 생각해볼 수 있습니다."
      ]
    },
    relatedConcepts: ["불리언 대수", "조건문", "디지털 로직"],
    learningStyle: ["개념적 학습", "분석적 사고"],
    questionType: "conceptual",
    answerFormat: "Single Choice",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 AND 연산자는 'and'로 표현됩니다."]
      }
    },
    choices: [
      { id: "A", text: "AND 게이트에서 두 입력 중 하나만 True여도 결과는 True이다." },
      { id: "B", text: "AND 게이트에서 두 입력이 모두 False일 때만 결과가 False이다." },
      { id: "C", text: "AND 게이트에서 두 입력이 모두 True일 때만 결과가 True이다." }
    ],
    correctAnswer: "C",
    solution: "정답은 C입니다. AND 게이트에서는 모든 입력이 True일 때만 결과가 True입니다. A는 OR 게이트의 특성을 설명하고 있습니다. B는 잘못된 설명입니다; AND 게이트에서는 하나라도 False이면 결과가 False입니다. D 역시 잘못된 설명으로, AND 게이트의 특성과 반대되는 내용입니다.",
    examples: [
      {
        input: "True and True",
        output: "True"
      },
      {
        input: "True and False",
        output: "False"
      }
    ],
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["없음"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.3,
      guessingParameter: 0.25
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-14",
    lastUpdated: "2024-10-14",
    versionNumber: 1.1
  },

  // operators_009 // Logical Operators // multiple_choice - OR 게이트 이해하기
  {
  problemId: "operators_009",
  image: operators_005,
  type: "multiple_choice",
  title: "OR 게이트 이해하기",
  description: `OR 게이트는 논리 회로의 기본 요소 중 하나입니다. OR 게이트의 특성은 다음과 같습니다:

  - 두 개 이상의 입력을 받습니다.
  - 하나 이상의 입력이 1(True)이면 출력이 1(True)입니다.
  - 모든 입력이 0(False)일 때만 출력이 0(False)입니다.

  다음 중 OR 게이트의 동작을 올바르게 설명한 것은 무엇일까요?`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "OR Gate",
  difficulty: 2,
  difficultyDescription: "OR 게이트의 기본 개념을 이해하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Understand",
  cognitiveProcess: ["이해", "분석"],
  knowledgeDimension: ["개념적 지식"],
  prerequisiteKnowledge: [
    "불리언 대수의 기초",
    "OR 연산의 기본 개념"
  ],
  estimatedTime: "2분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Algebra",
    "OR Gate"
  ],
  programmingConcepts: ["논리 연산", "불리언 로직", "OR 게이트"],
  skillsPracticed: ["논리적 사고", "연산자 이해"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "OR 게이트의 기본 동작 이해",
    "OR 연산의 결과 예측 능력 향상"
  ],
  commonMistakes: [
    "AND와 OR 연산의 결과를 혼동함",
    "불리언 값의 의미를 잘못 해석함"
  ],
  hints: {
    beginner: [
      "OR 연산은 하나 이상의 조건이 참이면 참입니다.",
      "모든 입력이 거짓일 때만 결과가 거짓입니다.",
      "False는 0, True는 1로 생각해볼 수 있습니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "조건문", "디지털 로직"],
  learningStyle: ["개념적 학습", "분석적 사고"],
  questionType: "conceptual",
  answerFormat: "Single Choice",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 OR 연산자는 'or'로 표현됩니다."]
    }
  },
  choices: [
    { id: "A", text: "OR 게이트에서 두 입력이 모두 True일 때만 결과가 True이다." },
    { id: "B", text: "OR 게이트에서 두 입력 중 하나라도 True이면 결과는 True이다." },
    { id: "C", text: "OR 게이트에서 두 입력이 모두 False일 때만 결과가 True이다." },
    { id: "D", text: "OR 게이트에서 두 입력 중 하나만 False여도 결과는 False이다." }
  ],
  correctAnswer: "B",
  solution: "정답은 B입니다. OR 게이트에서는 하나 이상의 입력이 True이면 결과가 True입니다. A는 AND 게이트의 특성을 설명하고 있습니다. C는 완전히 잘못된 설명입니다; OR 게이트에서 모든 입력이 False일 때 결과가 False입니다. D 역시 잘못된 설명으로, OR 게이트의 특성과 반대되는 내용입니다.",
  examples: [
    {
      input: "True or False",
      output: "True"
    },
    {
      input: "False or False",
      output: "False"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["없음"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.0
  },

  // operators_010 // Logical Operators // code - 3 또는 5의 배수 확인하기
{
  problemId: "operators_010",
  type: "code",
  title: "3 또는 5의 배수 확인하기",
  description: `주어진 숫자가 3의 배수이거나 5의 배수일 때 True를 출력하고, 그렇지 않으면 False를 출력하는 코드입니다.
  조건 A(3의 배수)와 조건 B(5의 배수)를 논리 연산자 OR를 사용하여 표현해봅니다.

  3의 배수는 3으로 나누었을 때 나머지가 0이고, 5의 배수는 5로 나누었을 때 나머지가 0입니다.
  
  만약 두 조건이 모두 참일때만, 참을 출력하고 싶다면 and 연산자를 사용해야합니다.`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "OR Operation",
  difficulty: 2,
  difficultyDescription: "기본적인 논리 연산을 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "논리 연산자",
    "나머지 연산자"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Expressions"
  ],
  programmingConcepts: ["논리 연산", "나머지 연산", "불리언 표현식"],
  skillsPracticed: ["논리 연산자 사용", "간결한 코드 작성"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "OR 연산자의 활용 방법 이해",
    "나머지 연산자를 이용한 배수 판별 방법 학습",
    "조건문 없이 논리 연산을 수행하는 능력 향상"
  ],
  commonMistakes: [
    "OR 연산자 대신 AND 연산자를 사용함",
    "나머지 연산자의 사용법을 잘못 이해함",
    "불필요하게 복잡한 표현식을 사용함"
  ],
  hints: {
    beginner: [
      "3의 배수는 3으로 나누었을 때 나머지가 0입니다.",
      "5의 배수는 5로 나누었을 때 나머지가 0입니다.",
      "OR 연산자는 '또는'의 의미로, 두 조건 중 하나만 참이어도 전체가 참입니다."
    ],
    intermediate: [
      "나머지 연산자 %를 사용하여 배수를 확인할 수 있습니다.",
      "논리 연산의 결과는 그 자체로 True 또는 False입니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "나머지 연산", "단축 평가"],
  learningStyle: ["실천적 학습"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 OR 연산자는 'or'로 표현됩니다."]
    }
  },
  initialCode: `number = 15
# 아래 줄을 수정하여 number가 3의 배수이거나 5의 배수일 때 True를 출력하도록 만드세요.
print(number % 3 == 0 or number % 5 == 0)`,
  testCases: [
    { input: "15", expected: "True" }
  ],
  solution: `number = 15
print(number % 3 == 0 or number % 5 == 0)`,
  examples: [
    {
      input: "15",
      output: "True"
    },
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.1
},

// operators_011 // Logical Operators // code - 2와 3의 공배수 확인하기
{
  problemId: "operators_011",
  type: "code",
  title: "2와 3의 공배수 확인하기",
  description: `주어진 숫자가 2의 배수이면서 동시에 3의 배수일 때(즉, 2와 3의 공배수일 때) True를 출력하고, 그렇지 않으면 False를 출력하는 코드를 작성하세요.
  공배수는 두 조건(2의 배수이기도 하고, 3의 배수이기도 할때)이 모두 True일때를 뜻합니다. and 연산자를 사용해보세요.

  [입력 예시]
  6

  [출력 예시]
  True`,
  subject: "Computer Science",
  language: "Python",
  version: "3.x",
  topic: "Logical Operators",
  subTopic: "AND Operation",
  difficulty: 2,
  difficultyDescription: "기본적인 논리 연산을 활용하는 중급 수준의 문제입니다.",
  cognitiveLevel: "Apply",
  cognitiveProcess: ["적용", "분석"],
  knowledgeDimension: ["절차적 지식"],
  prerequisiteKnowledge: [
    "Python의 기본 문법",
    "논리 연산자",
    "나머지 연산자"
  ],
  estimatedTime: "5분",
  tags: [
    "Python",
    "Intermediate",
    "Logical Operators",
    "Boolean Expressions"
  ],
  programmingConcepts: ["논리 연산", "나머지 연산", "불리언 표현식"],
  skillsPracticed: ["논리 연산자 사용", "간결한 코드 작성"],
  standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
  learningObjectives: [
    "AND 연산자의 활용 방법 이해",
    "나머지 연산자를 이용한 배수 판별 방법 학습",
    "조건문 없이 논리 연산을 수행하는 능력 향상"
  ],
  commonMistakes: [
    "AND 연산자 대신 OR 연산자를 사용함",
    "나머지 연산자의 사용법을 잘못 이해함",
    "불필요하게 복잡한 표현식을 사용함"
  ],
  hints: {
    beginner: [
      "2의 배수는 2로 나누었을 때 나머지가 0입니다.",
      "3의 배수는 3으로 나누었을 때 나머지가 0입니다.",
      "AND 연산자는 '그리고'의 의미로, 두 조건이 모두 참일 때만 전체가 참입니다."
    ],
    intermediate: [
      "나머지 연산자 %를 사용하여 배수를 확인할 수 있습니다.",
      "논리 연산의 결과는 그 자체로 True 또는 False입니다."
    ]
  },
  relatedConcepts: ["불리언 대수", "나머지 연산", "단축 평가"],
  learningStyle: ["실천적 학습"],
  questionType: "programming",
  answerFormat: "Code",
  languageSpecifics: {
    python: {
      version: "3.x",
      syntaxNotes: ["Python에서 AND 연산자는 'and'로 표현됩니다."]
    }
  },
  initialCode: `number = 6
# 아래 줄을 수정하여 number가 2의 배수이면서 동시에 3의 배수일 때 True를 출력하도록 만드세요.
print()
`,
  testCases: [
    { input: "15", expected: "True" },
  ],
  solution: `number = 6
print(number % 2 == 0 and number % 3 == 0)

number = 8
print(number % 2 == 0 and number % 3 == 0)
`,
  examples: [
    {
      input: "6",
      output: "True"
    },
    {
      input: "8",
      output: "False"
    }
  ],
  accessibilityFeatures: ["특별한 요구 사항 없음"],
  technologyRequirements: ["Python 3.x 인터프리터"],
  estimatedDifficultyParameters: {
    discriminationIndex: 0.3,
    guessingParameter: 0.25
  },
  statisticalData: {
    attempts: 0,
    successRate: 0,
    suggestedProblem: null
  },
  progressData: {
    isCompleted: false
  },
  author: "SlopeXcelerity",
  dateCreated: "2024-10-14",
  lastUpdated: "2024-10-14",
  versionNumber: 1.0
},
  //id: "python-data-structures-beginner",
  //title: "데이터를 저장하는 또 다른 방법",
  //topics: ["List Operations", "Tuple Characteristics", "Dictionary Usage", "split"]
  ////////////////////////////////

  // code - 두 숫자 더하기
  {
    problemId: "python_arithmetic_001",
    title: "두 숫자 더하기",
    description: `두 개의 정수를 공백을 두고 입력받아 그 합을 출력하는 코드를 작성하세요.

                  [입력 예시 - TestCase 1]
                  3 5

                  [출력 예시]
                  8`,
    subject: "Computer Science",
    language: "Python",
    version: "3.x",
    topic: "split",
    subTopic: "Addition",
    difficulty: 1,
    difficultyDescription: "기본적인 산술 연산을 다루는 초급 수준의 문제입니다.",
    cognitiveLevel: "Apply",
    cognitiveProcess: ["적용", "이해"],
    knowledgeDimension: ["절차적 지식"],
    prerequisiteKnowledge: [
      "Python의 기본 문법",
      "함수 정의",
      "기본 산술 연산"
    ],
    estimatedTime: "5분",
    tags: [
      "Python",
      "Beginner",
      "Arithmetic",
      "Functions"
    ],
    programmingConcepts: ["함수", "산술 연산", "반환 값"],
    skillsPracticed: ["함수 작성", "덧셈 연산"],
    standardsAlignment: ["CSTA K-12 CS Standards", "ISTE Standards for Students"],
    learningObjectives: [
      "정수 입력받기",
      "기본적인 산술 연산 수행 방법 이해"
    ],
    commonMistakes: [
      "함수에서 결과를 출력하지 않고 반환해야 함",
      "입력값을 정수로 변환하지 않음"
    ],
    hints: {
      beginner: [
        "input()은 모든 입력을 문자열로 받기 때문에 정수로 변환해야 합니다.",
        "변수 하나씩 int() 함수를 사용해서 변환해보세요."
      ],
      intermediate: [
        "map() 함수를 사용하여 한 번에 여러 변수를 변환할 수 있습니다."
      ],
      advanced: [
        "a, b 변수대신 list 형태로 입력 받아보세요."
      ]
    },
    relatedConcepts: ["함수", "변수", "반환 값"],
    learningStyle: ["실천적 학습"],
    type: "code",
    questionType: "programming",
    answerFormat: "Code",
    languageSpecifics: {
      python: {
        version: "3.x",
        syntaxNotes: ["Python에서 함수는 def 키워드로 정의합니다."]
      }
    },
    initialCode: `# 두 개의 정수를 공백을 두고 입력받습니다.
### a, b를 정수로 변환하는 코드를 추가해보세요.
a, b = input().split()


#a와 b 변수를 더한 값을 출력합니다.
print(a + b)`,
    testCases: [
      { input: "3 5", expected: "8" },
      { input: "-2 7", expected: "5" },
    ],
    solution: `def sum_two_numbers(a, b):
    return a + b`,
    examples: [
      {
        input: `3 5`,
        output: "8"
      }
    ],
    image: null,
    accessibilityFeatures: ["특별한 요구 사항 없음"],
    technologyRequirements: ["Python 3.x 인터프리터"],
    estimatedDifficultyParameters: {
      discriminationIndex: 0.2,
      guessingParameter: 0.1
    },
    statisticalData: {
      attempts: 0,
      successRate: 0,
      suggestedProblem: null
    },
    progressData: {
      isCompleted: false
    },
    author: "SlopeXcelerity",
    dateCreated: "2024-10-10",
    lastUpdated: "2024-10-10",
    versionNumber: 1.0
  },
];