import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google'; // 추가
import { AuthProvider } from './context/AuthContext'; // AuthProvider import 추가
import PrivateRoute from './components/private/PrivateRoute';
import Header from './components/header/Header'; // 랜딩페이지 헤더
import MobileMenu from './components/header/MobileMenu'; // 모바일 메뉴
import Footer from './components/footer/Footer'; // 푸터
import LandingPage from './pages/LandingPage'; // 랜딩페이지
import Popup from './components/popup/Popup'; // 팝업
import FirebaseAuthListener from './FirebaseAuthListener'; // 파이어베이스 인증
import Modal from 'react-modal'; 
import GoodbyePage from './components/lms/myInfo/GoodbyeScreen';

// Category
import CompanyPage from './pages/CompanyPage'; // 회사 페이지
import CareersPage from './pages/CareersPage'; // 채용 페이지
import ClassPage from './pages/ClassPage'; // 교육 페이지
import ZoomPage from './pages/ZoomPage'; // 줌 페이지
import CustomerCenterPage from './pages/CustomerCenterPage'; // 고객센터 페이지
import ContactPage from './pages/ContactPage'; // 문의 페이지
import PayPage from './pages/PayPage'; // 결제 페이지

// Auth
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/signup/SignupPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import FindEmailPage from './pages/auth/FindEmailPage';
import SignupModal from './components/modal/SignupModal';

// LMS
import LmsLandingPage from './pages/lms/LmsLandingPage';
import { LmsProvider } from './context/LmsContext'; // LmsProvider 추가

// Custom
import CustomBtn from './components/landing/customBtn/CustomBtn';

Modal.setAppElement('#root');  // 또는 '#app' 등 실제 루트 요소의 ID를 사용

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppContent = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { theme } = useTheme();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const isLmsPage = location.pathname.startsWith('/lms');
  const isMainPage = location.pathname === '/';

  return (
    <div className={`${theme} flex flex-col min-h-screen w-screen max-w-[100vw] overflow-x-hidden`}>
      <div className="flex-grow">
        <FirebaseAuthListener />
        <ScrollToTop />
        {!isLmsPage && <Header toggleMobileMenu={toggleMobileMenu} isMainPage={isMainPage} />}
        {!isLmsPage && <MobileMenu isOpen={isMobileMenuOpen} closeMenu={closeMobileMenu} />}
        {isMainPage && <Popup />}

        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* Category Routes */}
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/class" element={<PrivateRoute element={ClassPage} />} />
          <Route path="/zoom" element={<PrivateRoute element={ZoomPage} />} />
          <Route path="/customer" element={<CustomerCenterPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/pricing" element={<PayPage />} />

          {/* Auth Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/find-email" element={<FindEmailPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/goodbye" element={<GoodbyePage />} />

          {/* LMS Routes */}
          <Route path="/lms/*" element={
            <LmsProvider> {/* LmsProvider로 감싸기 */}
              <LmsLandingPage />
            </LmsProvider>
          } /> 

        </Routes>

        <SignupModal />
      </div>
      {!isLmsPage && <Footer />}
      <CustomBtn />
    </div>
  );
};

const App = () => {
  const clientId = '60395917627-k3uhol2ndl8kj5p6namrmv1ifbgolae1.apps.googleusercontent.com'; // 실제 클라이언트 ID로 교체

  return (
    <ThemeProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <AuthProvider> {/* AuthProvider 추가 */}
          <Router>
            <AppContent />
          </Router>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;