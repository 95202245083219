export const pythonCurriculum = [
  {
    id: "python-beginner",
    title: "🐍 파이썬 기초 학습 🚀",
    description: "파이썬 프로그래밍 언어의 기초를 배우는 과정입니다. 기본 문법부터 객체지향 프로그래밍까지 다룹니다.",
    topics: ["Basic Syntax", "Data Structures", "Functions", "Exception Handling", "Object-Oriented Programming", "Modules and Libraries", "File Handling"],
    chapters: [
      {
        id: "data-types-variables",
        title: "데이터 종류와 가장 기본적인 저장방법",
        description: "파이썬에서 다루는 데이터의 종류와 데이터를 저장하는 방법에 대하여 학습합니다.",
        status: "current",
        topics: ["Programming Fundamentals", "Data Types", "Integers", "Floats", "Strings", "Booleans", "Variables"],
        prerequisites: null
      },
      {
        id: "built-in-functions",
        title: "기본 기능 사용해보기",
        description: "파이썬에서 자주 사용되는 기본적인 기능을 알아보고 직접 사용해봅니다.",
        status: "locked",
        topics: ["functions", "print", "input", "type"],
        prerequisites: ["data-types-variables"]
      },
      {
        id: "data-storage-and-access",
        title: "데이터는 어떻게 컴퓨터에 저장될까?",
        description: "데이터가 어떻게 저장되고, 어떻게 꺼내쓰는지 알아봅니다.",
        status: "locked",
        topics: ["memory_management", "Concept of Index", "Slicing"],
        prerequisites: ["built-in-functions"]
      },
      {
        id: "operators",
        title: "프로그래밍 연산자",
        description: "파이썬에서 사용되는 다양한 연산자들의 사용법을 학습합니다.",
        status: "locked",
        topics: ["Arithmetic Operators", "Comparison Operators", "Logical Operators", "Assignment Operators", "Bitwise Operators"],
        prerequisites: ["data-storage-and-access"]
      },
      {
        id: "error-handling",
        title: "오류에 대하여",
        description: "파이썬에서 발생할 수 있는 다양한 오류 유형과 디버깅 방법을 학습합니다.",
        status: "locked",
        topics: ["Syntax Errors", "Runtime Errors", "Logical Errors"],
        prerequisites: ["built-in-functions"]
      },
      {
        id: "conditional-statements",
        title: "조건문",
        description: "if, elif, else를 사용한 조건문 작성 방법을 배웁니다.",
        status: "locked",
        topics: ["if Statements", "else Statements", "elif Statements", "Nested Conditionals"],
        prerequisites: ["error-handling"]
      },
      {
        id: "loops",
        title: "반복문",
        description: "for 루프와 while 루프를 사용한 반복 구조를 학습합니다.",
        status: "locked",
        topics: ["for Loops", "while Loops", "break and continue Statements"],
        prerequisites: ["conditional-statements"]
      },
      {
        id: "python-data-structures-beginner",
        title: "데이터를 저장하는 또 다른 방법",
        description: "파이썬의 주요 데이터 구조인 리스트, 튜플, 딕셔너리의 사용법을 배웁니다.",
        status: "locked",
        topics: ["List Operations", "Tuple Characteristics", "Dictionary Usage", "split"],
        prerequisites: ["loops"]
      },
      {
        id: "functions",
        title: "함수",
        description: "사용자 정의 함수의 작성과 활용 방법을 학습합니다.",
        status: "locked",
        topics: ["Function Definition", "Parameters", "Return Values", "Scope"],
        prerequisites: ["data-structures"]
      },
      {
        id: "exception-handling",
        title: "예외처리",
        description: "try-except 구문을 사용한 예외 처리 방법을 배웁니다.",
        status: "locked",
        topics: ["try-except Blocks", "Multiple Exception Handling", "finally Clause"],
        prerequisites: ["functions"]
      },
      {
        id: "oop",
        title: "객체지향 프로그래밍",
        description: "클래스와 객체를 사용한 객체지향 프로그래밍의 기초를 학습합니다.",
        status: "locked",
        topics: ["Class Definition", "Object Creation", "Inheritance", "Polymorphism"],
        prerequisites: ["exception-handling"]
      },
      {
        id: "modules",
        title: "모듈의 개념",
        description: "모듈의 개념과 사용 방법을 이해합니다.",
        status: "locked",
        topics: ["Module Importing", "Module Creation", "Packages"],
        prerequisites: ["oop"]
      },
      {
        id: "libraries",
        title: "라이브러리 이해하기",
        description: "파이썬의 주요 라이브러리들과 그 사용법을 학습합니다.",
        status: "locked",
        topics: ["Standard Libraries", "Third-party Libraries", "pip Usage"],
        prerequisites: ["modules"]
      },
      {
        id: "file-io",
        title: "파일 입출력",
        description: "파일 읽기와 쓰기 등 파일 입출력 작업 방법을 배웁니다.",
        status: "locked",
        topics: ["File Opening/Closing", "Text File Reading/Writing", "CSV File Handling"],
        prerequisites: ["libraries"]
      }
    ]
  }
];

export const softwareEngineeringCurriculum = [
  {
    id: "software-engineering",
    title: "소프트웨어 공학 과정",
    description: "소프트웨어 개발 프로세스와 방법론을 학습하는 과정입니다. 요구사항 분석부터 유지보수까지 전체 소프트웨어 생명주기를 다룹니다.",
    topics: ["Software Development Process", "Requirements Engineering", "Software Design", "Software Testing", "Software Maintenance", "Project Management"],
    chapters: [
      {
        id: "introduction",
        title: "소프트웨어 공학 개요",
        description: "소프트웨어 공학의 기본 개념과 중요성에 대해 학습합니다.",
        status: "current",
        topics: ["Software Engineering Basics", "Software Process Models"],
        prerequisites: []
      },
      {
        id: "requirements",
        title: "요구사항 공학",
        description: "요구사항 추출, 분석, 명세화 및 관리 방법을 배웁니다.",
        status: "locked",
        topics: ["Requirements Elicitation", "Requirements Analysis", "Requirements Specification"],
        prerequisites: ["introduction"]
      },
      {
        id: "design",
        title: "소프트웨어 설계",
        description: "소프트웨어 아키텍처 및 상세 설계 기법을 학습합니다.",
        status: "locked",
        topics: ["Software Architecture", "Design Patterns", "UML"],
        prerequisites: ["introduction", "requirements"]
      },
      {
        id: "testing",
        title: "소프트웨어 테스팅",
        description: "다양한 테스팅 기법과 테스트 케이스 설계 방법을 배웁니다.",
        status: "locked",
        topics: ["Testing Techniques", "Test Case Design", "Test-Driven Development"],
        prerequisites: ["design"]
      },
      {
        id: "maintenance",
        title: "소프트웨어 유지보수",
        description: "소프트웨어 유지보수 전략과 리팩토링 기법을 학습합니다.",
        status: "locked",
        topics: ["Software Maintenance", "Refactoring", "Legacy Systems"],
        prerequisites: ["testing"]
      }
    ]
  }
];

export const dataStructuresCurriculum = [
  {
    id: "data-structures-beginner",
    title: "자료구조 기초 과정",
    description: "프로그래밍에서 사용되는 기본적인 자료구조들을 학습하는 과정입니다. 배열부터 그래프까지 다양한 자료구조의 개념과 구현 방법을 다룹니다.",
    topics: ["Arrays", "Linked Lists", "Stacks", "Queues", "Trees", "Graphs", "Algorithm Complexity"],
    chapters: [
      {
        id: "arrays",
        title: "배열",
        description: "배열의 기본 개념과 활용 방법을 학습합니다.",
        status: "locked",
        topics: ["Static Arrays", "Dynamic Arrays", "Time Complexity of Arrays"],
        prerequisites: [] // 선행 학습 필요 없음
      },
      {
        id: "linked-lists",
        title: "연결 리스트",
        description: "단일 및 이중 연결 리스트의 구조와 연산을 이해합니다.",
        status: "locked",
        topics: ["Singly Linked Lists", "Doubly Linked Lists", "Circular Linked Lists"],
        prerequisites: ["arrays"] // 'arrays' 챕터가 선행되어야 함
      },
      {
        id: "stacks-queues",
        title: "스택과 큐",
        description: "스택과 큐의 동작 원리와 응용 사례를 학습합니다.",
        status: "locked",
        topics: ["Characteristics of Stacks", "Characteristics of Queues", "Application Examples"],
        prerequisites: ["arrays", "linked-lists"] // 이전 챕터들이 선행되어야 함
      },
      {
        id: "trees",
        title: "트리",
        description: "트리 구조의 기본 개념과 다양한 트리 유형을 학습합니다.",
        status: "locked",
        topics: ["Binary Trees", "Balanced Trees", "Binary Search Trees"],
        prerequisites: ["stacks-queues"] // 'stacks-queues' 챕터가 선행되어야 함
      },
      {
        id: "graphs",
        title: "그래프",
        description: "그래프의 정의와 다양한 그래프 알리즘을 학습합니다.",
        status: "locked",
        topics: ["Definition of Graphs", "Graph Representations", "Traversal Algorithms"],
        prerequisites: ["trees"] // 'trees' 챕터가 선행되어야 함
      }
    ]
  }
];

export const computerArchitectureCurriculum = [
  {
    id: "computer-architecture-beginner",
    title: "컴퓨터 구조 기초 과정",
    description: "컴퓨터의 하드웨어 구조와 동작 원리를 학습하는 과정입니다. CPU, 메모리, 입출력 시스템 등 컴퓨터의 주요 구성 요소들을 다룹니다.",
    topics: ["Computer Components", "CPU Architecture", "Memory Systems", "Input/Output Systems", "Parallel Processing", "Performance Evaluation"],
    chapters: [
      {
        id: "introduction",
        title: "컴퓨터 구조 소개",
        description: "컴퓨터 구조의 기본 개념과 역사적 발전을 학습합니다.",
        status: "locked",
        topics: ["Definition of Computer Architecture", "Historical Development", "Basic Components"],
        prerequisites: [] // 선행 학습 필요 없음
      },
      {
        id: "cpu-architecture",
        title: "CPU 아키텍처",
        description: "CPU의 내부 구조와 작동 원리를 이해합니다.",
        status: "locked",
        topics: ["Arithmetic Logic Unit (ALU)", "Control Unit", "Registers", "Pipelining"],
        prerequisites: ["introduction"] // 'introduction' 챕터가 선행되어야 함
      },
      {
        id: "memory-hierarchy",
        title: "메모리 계층 구조",
        description: "메모리의 계층 구조와 각 계층의 역할을 학습합니다.",
        status: "locked",
        topics: ["Cache Memory", "Main Memory", "Secondary Storage"],
        prerequisites: ["cpu-architecture"] // 'cpu-architecture' 챕터가 선행되어야 함
      },
      {
        id: "input-output",
        title: "입출력 시스템",
        description: "입출력 장치의 구조와 인터페이스 방법을 학습합니다.",
        status: "locked",
        topics: ["Types of I/O Devices", "Bus Structures", "Interrupts and DMA"],
        prerequisites: ["memory-hierarchy"] // 'memory-hierarchy' 챕터가 선행되어야 함
      },
      {
        id: "parallel-processing",
        title: "병렬 처리",
        description: "병렬 처리의 개념과 다양한 병렬 처리 기법을 학습합니다.",
        status: "locked",
        topics: ["Multicore Processors", "Superscalar Architecture", "Multithreading"],
        prerequisites: ["cpu-architecture", "input-output"] // 해당 챕터들이 선행되어야 함
      }
    ]
  }
];