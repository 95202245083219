import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com'; // 패키지 변경
import { motion, AnimatePresence } from 'framer-motion';

// 컴포넌트 외부에 초기화 코드 추가
emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const formRef = useRef(null);
  const nameInputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen && isMobile && nameInputRef.current) {
      setTimeout(() => {
        const yOffset = -window.innerHeight / 4;
        const y = nameInputRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }
  }, [isOpen, isMobile]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState({
    loading: false,
    success: '',
    error: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateForm = () => {
    const { name, email, subject, message } = formData;
    if (!name || !email || !subject || !message) {
      setStatus({ ...status, error: '모든 필드를 입력해주세요.', success: '' });
      return false;
    }
    // 이메일 형식 확인
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setStatus({ ...status, error: '유효한 이메일 주소를 입력해주세요.', success: '' });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: '', error: '' });

    if (!validateForm()) {
      setStatus(prev => ({ ...prev, loading: false }));
      return;
    }

    const templateParams = {
      from_name: formData.name, // 보내는 사람 이름 (회사 또는 서비스 이름)
      from_email: formData.email, // 보내는 사람 이메일 (회사 또는 서비스 이메일)
      reply_to: process.env.REACT_APP_SENDER_EMAIL, // 답장 받을 이메일 (사용자가 입력한 이메일)
      to_name: process.env.REACT_APP_SENDER_NAME, // 받는 사람 이름 (사용자가 입력한 이름)
      subject: formData.subject,
      message: formData.message
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams
    )
    .then((response) => {
      console.log('성공:', response);
      setStatus({ loading: false, success: '문의가 성공적으로 전송되었습니다!', error: '' });
      setFormData({ name: '', email: '', subject: '', message: '' });
    }, (error) => {
      console.error('메일 전송 실패:', error);
      setStatus({ loading: false, success: '', error: '문의 전송에 실패했습니다. 다시 시도해주세요.' });
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleForm = () => {
    if (isOpen) {
      setIsOpen(false);
      scrollToTop(); // 항상 최상단으로 스크롤
    } else {
      setIsOpen(true);
    }
  };

  const variants = {
    desktop: {
      open: { opacity: 1, x: 0 },
      closed: { opacity: 0, x: '-100%' },
    },
    mobile: {
      open: { opacity: 1, height: 'auto', marginTop: '1rem' },
      closed: { opacity: 0, height: 0, marginTop: 0 },
    }
  };

  return (
    <div className="pt-28 pb-12 px-4 sm:px-6 lg:px-8 flex justify-center">
      <div className={`max-w-7xl w-full transition-all duration-300 ${isOpen ? 'md:w-full' : 'md:w-1/2'}`}>
        <div className={`flex flex-col md:flex-row gap-8 ${isOpen ? 'justify-between' : 'justify-center'}`}>
          {/* 회사 소개 영역 */}
          <div className={`w-full ${isOpen ? 'md:w-1/2' : 'md:w-full'} bg-[#D00070] text-white p-8 rounded-lg relative h-[600px] transition-all duration-300`}>
            <h3 className="text-5xl font-extrabold mb-12">CONTACT US</h3>
            <p className="mb-60">사업자 등록 준비중</p>
            <button 
              onClick={toggleForm}
              className="absolute bottom-8 right-8 bg-white text-[#D00070] px-6 py-2 rounded-full font-bold hover:bg-red-100 transition duration-300"
            >
              {isOpen ? '닫기' : '여기서 곧바로 메일 문의하기'}
            </button>
          </div>

          {/* 이메일 양식 영역 */}
          <div className={`w-full md:w-1/2 overflow-hidden transition-all duration-300 ${isOpen ? 'opacity-100 max-h-[2000px]' : 'opacity-0 max-h-0 md:w-0'}`}>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  ref={formRef}
                  className="bg-white rounded-lg"
                  variants={isMobile ? variants.mobile : variants.desktop}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  transition={{ 
                    type: "spring", 
                    stiffness: 300, 
                    damping: 30,
                    duration: 0.3 
                  }}
                >
                  <div className="bg-white p-8 rounded-lg">
                    {status.error && <p className="text-red-500 text-center mb-4">{status.error}</p>}
                    {status.success && <p className="text-[#D00070] text-center mb-4">{status.success}</p>}
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <div>
                        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                          이름
                        </label>
                        <input
                          ref={nameInputRef}
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#D00070]"
                          placeholder="이름을 입력하세요"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                          이메일
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#D00070]"
                          placeholder="이메일을 입력하세요"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="subject" className="block text-gray-700 font-bold mb-2">
                          제목
                        </label>
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#D00070]"
                          placeholder="문의 제목을 입력하세요"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="message" className="block text-gray-700 font-bold mb-2">
                          문의 내용
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          rows="5"
                          value={formData.message}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#D00070]"
                          placeholder="문의 내용을 입력하세요"
                          required
                        ></textarea>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className={`w-full py-2 px-4 rounded-md text-white font-bold transition duration-300 ${
                            status.loading
                              ? 'bg-gray-400 cursor-not-allowed'
                              : 'bg-[#D00070] hover:bg-[#C00065]'
                          }`}
                          disabled={status.loading}
                        >
                          {status.loading ? '전송 중...' : '문의 전송'}
                        </button>
                      </div>
                    </form>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;